// import { DrcGrid } from 'driscolls-react-components';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import withStyles from '@material-ui/styles/withStyles';
// import { Toolbar } from 'react-data-grid-addons';
import { handleFilterChange } from '../util/filter';

let styles = () => ({
    grid: {
        '& .selected > div': {
            backgroundColor: '#cfc3deba !important'
        },
        '& .nonSelected > div': {
            backgroundColor: '#d9d0e4 !important'
        },
        '& .react-grid-Header, & .react-grid-HeaderCell': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important'
        },
        '& .react-grid-HeaderCell > div': {
            color: 'white'
        },
        '& .react-grid-Toolbar .btn:after': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            height: '27px',
            color: 'white'
        },
        '& .react-grid-Header': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important'
        }
    }
});
class GridLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oFilters: {}
        };
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    handleFilterChange = (filter) => {
        let filters = this.state.oFilters;
        const newFilters = { ...filters };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        this.setState({ oFilters: newFilters });
    };

    render() {
        const { rows, columns, getCellActions, classes } = this.props;
        columns[0].width = 120;
        return (
            // <DrcGrid
            //     className={classes.grid}
            //     rows={handleFilterChange(rows, this.state.oFilters)}
            //     fullHeightOffset={10}
            //     columns={columns}
            //     hideCount={true}
            //     getCellActions={getCellActions}
            //     toolbar={<Toolbar enableFilter={true} />}
            //     onAddFilter={(filter) => this.setState({ filters: this.handleFilterChange(filter, this.state.filters) })}
            // />
            <div>Todo:Grid</div>
        );
    }
}

export default connect(null, null)(withOktaAuth(withStyles(styles)(GridLayout)));
