import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { DuThemeUtilities } from 'driscolls-react-utilities';

const styles = (theme) => ({
    snackError: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: theme.light.error,
            color: theme.light.text.error
        },
        '& .MuiSnackbarContent-message span': {
            fontSize: '1.2rem'
        },
        [theme.darkTheme]: {
            '& .MuiSnackbarContent-root': {
                backgroundColor: theme.dark.error,
                color: theme.dark.text.error
            }
        }
    },
    snackAlert: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.green,
            color: theme.light.text.inverted
        },
        '& .MuiSnackbarContent-message span': {
            fontSize: '1.2rem'
        },
        [theme.darkTheme]: {
            '& .MuiSnackbarContent-root': {
                backgroundColor: DuThemeUtilities.DefaultColors.primary.green,
                color: theme.dark.text.primary
            }
        }
    },
    messageList: {
        display: 'block'
    }
});

class DrcPageWarning extends React.Component {
    render() {
        const { className, classes, message, anchorVertical, anchorHorizontal, isError } = this.props;
        var msg = '',
            error = false;

        if (typeof message === 'string' || message instanceof String) {
            msg = <span>{message}</span>;
        } else {
            msg = message.map((m) => (
                <span key={m} className={classes.messageList}>
                    {m}
                </span>
            ));
        }

        if (isError === true || isError === undefined) {
            error = true;
        }

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: anchorVertical || 'bottom',
                    horizontal: anchorHorizontal || 'center'
                }}
                open={true}
                onClose={() => {}}
                className={`${error ? classes.snackError : classes.snackAlert} ${className || ''}`}
                message={msg}
            />
        );
    }
}

export default withStyles(styles)(DrcPageWarning);
