import { DriscollsTypography, DriscollsTypographyEnum, DriscollsTypographyStyles } from '@driscollsinc/style-guide-web2.0';
import style from './SiteMappingGrid.module.scss';
const SiteEntityInfo = ({ entity }) => {
    return (
        <DriscollsTypography
            ellipsis
            className={style.entityInfo}
            typographyStyle={DriscollsTypographyStyles.SUBTITLE5}
            type={DriscollsTypographyEnum.P}
        >
            {entity.Name}
        </DriscollsTypography>
    );
};

export default SiteEntityInfo;
