import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import withStyles from '@material-ui/styles/withStyles';
import { withOktaAuth } from '@okta/okta-react';
import { DrcButton, DrcDataGrid, DrcPanel, DrcTooltip } from 'driscolls-react-components';
import { Middleware } from 'one-ring';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { setOverrideData } from '../../actions/MasterActions';
import ConfirmDialog from '../../components/Override/ConfirmDialog';
import DeleteDialog from '../../components/Override/DeleteDialog';
import EditDialog from '../../components/Override/EditDialog';
import OverrideHeader from '../../components/Override/OverrideHeader';
import OverrideTable from '../../components/Override/OverrideTable';
import { END_DATE, RANCH_NAME, RANCH_NUMBER, START_DATE, MODIFIED_BY } from '../../language/english';
import APIEndPoints from '../../services/api';
import { DriscollsEditButton, DriscollsTrashButton } from '@driscollsinc/style-guide-web2.0';
import SimpleDataTable from '../../components/Override/SimpleDataTable';

let styles = (theme) => ({
    gridTitle: {
        marginTop: '0px',
        marginBottom: '8px',
        fontSize: '21px',
        fontWeight: '500',
        lineHeight: 'normal',
        color: '#4a773c'
    }
});

const PAGE_TYPE = 'override';

// list of columns for Data grid
const gridColumns = [
    { accessorKey: 'RanchNbr', header: 'Ranch Number' },
    { accessorKey: 'RanchName', header: 'Ranch Name' },
    { accessorKey: 'startDateString', header: 'Start Date' },
    { accessorKey: 'endDateString', header: 'End Date' },
    { accessorKey: 'ModifiedBy', header: 'Modified By' }
];

const getGridColumns = () => {
    const commonColumnProperties = {
        enableColumnFilter: true,
        enablePinning: false
    };
    return gridColumns.map((column) => {
        return {
            accessorKey: column.accessorKey,
            header: column.header,
            ...commonColumnProperties
        };
    });
};

const fields = [
    {
        key: 'RanchNbr',
        name: (
            <DrcTooltip tipText="Ranch Number">
                <span>{RANCH_NUMBER}</span>
            </DrcTooltip>
        ),
        isDisabled: true,
        inputType: 'input'
    },
    {
        key: 'RanchName',
        name: (
            <DrcTooltip tipText="Ranch Name">
                <span>{RANCH_NAME}</span>
            </DrcTooltip>
        ),
        inputType: 'input',
        isDisabled: true
    },
    {
        key: 'startDateString',
        name: (
            <DrcTooltip tipText="Start Date">
                <span>{START_DATE}</span>
            </DrcTooltip>
        ),
        inputType: 'dateTime',
        validationType: 'required'
    },
    {
        key: 'endDateString',
        name: (
            <DrcTooltip tipText="End Date">
                <span>{END_DATE}</span>
            </DrcTooltip>
        ),
        inputType: 'dateTime',
        validationType: 'required'
    },
    {
        key: 'ModifiedBy',
        name: (
            <DrcTooltip tipText="Modified By">
                <span>{MODIFIED_BY}</span>
            </DrcTooltip>
        )
    }
];

const Override = (props) => {
    const [messageDialog, setMessageDialog] = useState({
        showMessage: false,
        message: ''
    });

    const [dialogDeleteContent, setDialogDeleteContent] = useState({
        dialogOpen: false,
        dialogText: '',
        dialogConfirm: () => {
            /*  */
        },
        oldValue: {},
        editedValue: {}
    });

    const [dialogEditContent, setDialogEditContent] = useState({
        dialogEditOpen: false,
        oldValue: {},
        editedValue: {},
        isEnabled: false,
        isEdit: false,
        helperText: []
    });

    const [confirmDialogContent, setConfirmDialogContent] = useState({
        dialogConfirmOpen: false,
        dialogText: '',
        oldValue: {},
        editedValue: {},
        helperText: []
    });

    const gridActionColumns = [
        {
            accessorKey: 'action',
            header: 'Actions',
            enableColumnFilter: false,
            size: 80,
            enablePinning: false,
            cell: (row) => {
                const currentRow = row?.row?.original;
                return (
                    <div className="iconCell">
                        <DriscollsEditButton className="" onClick={() => editActionHandler(currentRow)} />
                        <DriscollsTrashButton isModalRequired={false} onClick={() => deleteActionHandler(currentRow)} />
                    </div>
                );
            }
        }
    ];

    const overrideTableGridColumns = useMemo(() => [...gridActionColumns, ...getGridColumns()], []);

    useEffect(() => {
        getData(true);
    }, []);

    const getData = async (defaultLoadingMessage) => {
        props.authService.getAccessToken().then((token) => {
            Middleware.Send(
                PAGE_TYPE,
                token,
                APIEndPoints.OVERRIDE_DATA,
                'GET',
                { Type: PAGE_TYPE },
                { loadingMessage: defaultLoadingMessage ? 'Loading' : 'Fetching ranch override...' }
            ).then((data) => {
                props.setOverrideData(data.Data);
            });
        });
    };

    const deleteActionHandler = (row) => {
        setDialogDeleteContent({
            dialogOpen: true,
            dialogText: [
                <div style={{ fontSize: 20, marginBottom: 10 }}>Are you sure you want to delete Ranch Override?</div>,
                <SimpleDataTable data={[row]} columns={gridColumns} />
            ],
            editedValue: { ...row }
        });
    };

    const editActionHandler = (row) => {
        setDialogEditContent({
            dialogEditOpen: true,
            oldValue: { ...row },
            editedValue: { ...row },
            isEnabled: false,
            isEdit: true,
            helperText: []
        });
    };

    const updateOverrideData = (overrideData) => {
        console.log(overrideData);
        props.setOverrideData(overrideData);
    };

    const onSave = (status, severity, message, oldValue, newValue, helperText) => {
        switch (status) {
            case 'edit':
                setDialogEditContent({ dialogEditOpen: false });
                setConfirmDialogContent({
                    ...confirmDialogContent,
                    dialogConfirmOpen: true,
                    oldValue: oldValue,
                    editedValue: newValue,
                    helperText: helperText
                });
                break;
            case 'delete':
                setDialogDeleteContent({ dialogOpen: false });
                break;
            case 'confirm':
                setConfirmDialogContent({
                    dialogConfirmOpen: false
                });
                setMessageDialog({
                    showMessage: true,
                    severity: severity ? 'success' : 'error',
                    message: message
                });
                break;
            case 'cancel':
                setConfirmDialogContent({
                    dialogConfirmOpen: false
                });
                break;
            case 'close':
                setDialogEditContent({ dialogEditOpen: false });
                break;
            case 'deleteNo':
                setDialogDeleteContent({ dialogOpen: false });
                break;
            case 'deleteYes':
                setDialogDeleteContent({ dialogOpen: false });
                setMessageDialog({
                    showMessage: true,
                    severity: severity ? 'success' : 'error',
                    message: message
                });
                break;
            default:
                return;
        }
    };

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessageDialog({ showMessage: false });
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const { classes } = props;

    return (
        <div>
            <DrcPanel style={{ maxWidth: '100%', border: 'none', boxShadow: '0 2px 4px 0 #C3C3C3', marginBottom: '0px' }} className={classes.layout}>
                <DrcPanel style={{ maxWidth: '100%' }}>
                    <OverrideHeader getData={getData}></OverrideHeader>
                </DrcPanel>
                {props.ranchOverrideData && (
                    <div>
                        <h2 className={classes.gridTitle}>Ranch Override</h2>
                        <OverrideTable rows={props.ranchOverrideData} gridColumns={overrideTableGridColumns}></OverrideTable>
                    </div>
                )}
                {dialogDeleteContent.dialogOpen && (
                    <DeleteDialog
                        content={dialogDeleteContent}
                        overrideData={props.ranchOverrideData}
                        updateOverrideData={updateOverrideData}
                        onSave={onSave}
                    ></DeleteDialog>
                )}
                {dialogEditContent.dialogEditOpen && (
                    <EditDialog content={dialogEditContent} columns={fields} updateOverrideData={updateOverrideData} onSave={onSave}></EditDialog>
                )}
                {confirmDialogContent.dialogConfirmOpen && (
                    <ConfirmDialog
                        content={confirmDialogContent}
                        columns={gridColumns}
                        overrideData={props.ranchOverrideData}
                        updateOverrideData={updateOverrideData}
                        onSave={onSave}
                    ></ConfirmDialog>
                )}
            </DrcPanel>
            <Snackbar open={messageDialog.showMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={messageDialog.severity}>
                    {messageDialog.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        ranchOverrideData: state.masterReducer.ranchOverrideData
    };
};

export default connect(mapStateToProps, { setOverrideData })(withOktaAuth(withStyles(styles)(Override)));
