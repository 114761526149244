import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { DuCache } from 'driscolls-react-utilities';
import DrcDialog from './DrcDialog';
import DrcButton from './DrcButton';

const styles = (theme) => ({
    bar: {
        '& .MuiSnackbarContent-root': {
            minWidth: '80vw'
        }
    }
});

const HIDE_COOKIE_KEY = 'HideCookieAlert';

class DrcCookieMessage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showCookieAlert: false,
            showCookieDetails: false
        };
    }

    async componentDidMount() {
        var hideAlert = (await DuCache.Get(HIDE_COOKIE_KEY)) || false;

        this.setState({
            showCookieAlert: !hideAlert,
            showCookieDetails: false
        });
    }

    acceptCookies = () => {
        DuCache.Save(HIDE_COOKIE_KEY, true);
        this.setState({ showCookieAlert: false, showCookieDetails: false });
    };

    showCookieDetails = () => {
        this.setState({ showCookieDetails: true });
    };

    hideCookieDetails = () => {
        this.setState({ showCookieDetails: false });
    };

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Snackbar
                    open={this.state.showCookieAlert}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    message="This website requires the use of cookies. Please accept below in order to continue using this website"
                    action={
                        <React.Fragment>
                            <DrcButton isPrimary onClick={this.acceptCookies}>
                                Accept
                            </DrcButton>
                            <DrcButton isSecondary onClick={this.showCookieDetails}>
                                More Info
                            </DrcButton>
                        </React.Fragment>
                    }
                    className={classes.bar}
                />
                <DrcDialog
                    open={this.state.showCookieDetails}
                    title="How we use cookies"
                    buttons={
                        <React.Fragment>
                            <DrcButton isSecondary onClick={this.hideCookieDetails} floatRight>
                                Close
                            </DrcButton>
                            <DrcButton isPrimary onClick={this.acceptCookies} floatRight>
                                Accept and Close
                            </DrcButton>
                        </React.Fragment>
                    }
                >
                    <hr />
                    We use cookies in making sure valid users are logged in properly.
                    <br />
                    <br />
                    We also track anonymous usage statistics in order to provide a better experience for our users.
                    <br />
                    <br />
                    We never will sell or use this data to target individual users.
                    <br />
                </DrcDialog>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DrcCookieMessage);
