import React from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { DrcButton, DrcDialog } from 'driscolls-react-components';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import withStyles from '@material-ui/styles/withStyles';
import APIEndPoints from '../../services/api';
import { Middleware } from 'one-ring';
import { RANCH_OVERRIDE_UPDATE } from '../../language/english';
import { getPayload } from '../../util/utils';
import SimpleDataTable from './SimpleDataTable';

const PAGE_TYPE = 'override';
let styles = () => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '800px',
            width: '100%'
        }
    }
});

const ConfirmDialog = (props) => {
    const { content, columns } = props;

    const confirmDialogContent = () => {
        let dialogText = '';
        if (content.helperText > 0) {
            return;
        } else if (typeof content.editedValue === 'object') {
            dialogText = [
                <div style={{ fontSize: 20, marginBottom: 10 }}>Are you sure you want to change Ranch Override? </div>,
                <div className="row">                    
                    <SimpleDataTable className="col-sm-12" data={[content.oldValue]} columns={columns} />
                    <ArrowDownward className="col-sm-12" style={{ fontSize: 70 }} />                    
                    <SimpleDataTable className="col-sm-12" data={[content.editedValue]} columns={columns} />
                </div>
            ];
        } else {
            dialogText = 'Are you sure you want to change Ranch Override: ' + content.oldValue + '" to "' + content.editedValue + '"?';
        }
        return dialogText;
    };

    const onDialogYes = () => {
        let editPayload = getPayload(content.editedValue);
        props.authService.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.OVERRIDE_DATA, 'POST', editPayload, { loadingMessage: 'Updating ranch override...' })
                .then((_res) => {
                    let updatedData = props.overrideData.map((item) => {
                        if (item.RanchNbr !== content.oldValue.RanchNbr) {
                            return item;
                        } else {
                            return content.editedValue;
                        }
                    });
                    props.updateOverrideData(updatedData, 'edit');
                    props.onSave('confirm', true, RANCH_OVERRIDE_UPDATE);
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
                    props.onSave('confirm', false, message);
                });
        });
    };

    const onDialogNo = () => {
        props.onSave('cancel');
    };
    const { classes } = props;
    return (
        <DrcDialog
            className={classes.dialog}
            title={confirmDialogContent()}
            open={content.dialogConfirmOpen}
            buttons={
                <React.Fragment>
                    <DrcButton isPrimary onClick={onDialogYes}>
                        Accept Change
                    </DrcButton>
                    <DrcButton isSecondary onClick={onDialogNo}>
                        Oops, Just Kidding
                    </DrcButton>
                </React.Fragment>
            }
        />
    );
};

export default connect(null, null)(withOktaAuth(withStyles(styles)(ConfirmDialog)));
