import { DriscollsDataTable, DriscollsVContainer } from '@driscollsinc/style-guide-web2.0';
import styles from './QuartileBypassTable.module.css';

const QuartileBypassTable = ({ rows, gridColumns }) => {
    return (
        <DriscollsVContainer noPadding className={styles.container}>
            <DriscollsDataTable 
                tableClassName={styles.table} 
                isLoading={false} 
                columns={gridColumns} 
                data={rows} 
                hidePagination={true} 
            />
        </DriscollsVContainer>
    );
};

export default QuartileBypassTable;
