import { TSiteList } from '../../types/siteMapping';

export const SiteList: TSiteList = {
    Total: '3',
    Assignments: [
        {
            Region: {
                Id: '123',
                Name: 'QC-Iberia'
            },
            Site: {
                Id: '2',
                Name: 'Portugal'
            },
            Warehouses: [
                {
                    Id: '0001',
                    Name: 'WH-0001 Watsonville',
                },
                {
                    Id: '0003',
                    Name: 'WH-0003 Santa Maria',
                },
                {
                    Id: '0004',
                    Name: 'WH-0003 Santa Maria33',
                },
                {
                    Id: '0005',
                    Name: 'WH-0003 Santa Maria44',
                }
            ]
        },
        {
            Region: {
                Id: '1234',
                Name: 'QC-Iberia2'
            },
            Site: {
                Id: '21',
                Name: 'Portugal2'
            },
            Warehouses: [
                {
                    Id: '0011',
                    Name: 'WH-0011 Watsonville',
                },
                {
                    Id: '0013',
                    Name: 'WH-0013 Santa Maria',
                }
            ]
        },
        {
            Region: {
                Id: '56',
                Name: 'QC-Iberia3'
            },
            Site: {
                Id: '31',
                Name: 'Portugal3'
            },
            Warehouses: [
                {
                    Id: '0022',
                    Name: 'WH-0022 Watsonville3',
                },
                {
                    Id: '0033',
                    Name: 'WH-0033 Santa Maria3',
                }
            ]
        }
    ]
};
