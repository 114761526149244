import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DuIconSrc } from 'driscolls-react-utilities';

class DrcList extends React.Component {
    BerryType = (this.props.type || 'blue').toLowerCase();

    berryImage =
        this.BerryType === 'black'
            ? DuIconSrc.Black_Small_Png
            : this.BerryType === 'blue'
            ? DuIconSrc.Blue_Small_Png
            : this.BerryType === 'rasp'
            ? DuIconSrc.Rasp_Small_Png
            : DuIconSrc.Straw_Small_Png;

    styles = (theme) => ({
        list: {
            '& li': {
                listStyleImage: "url('" + this.berryImage + "')",
                padding: theme.spacing(0.25, 0),
                '& span': {
                    verticalAlign: 'top',
                    lineHeight: '23px',
                    display: 'inline-block'
                }
            }
        }
    });

    ul = (props) => <ul className={props.classes.list}>{props.children}</ul>;

    Styled = withStyles(this.styles)(this.ul);

    render() {
        const { children } = this.props;
        return <this.Styled>{children}</this.Styled>;
    }
}

export default DrcList;
