import { useState } from 'react';
import { TLoadOptions, TWarehouse } from '../../../../types/users';
import style from '../AssignmentCard.module.scss';
import WarehouseHeader from './WarehouseHeader';
import UsersList from './userslist/UsersList';
import {
    DriscollsTypography,
    DriscollsTypographyEnum,
    DriscollsTypographyStyles,
    DriscollsCardBody,
    DriscollsCard,
    DriscollsCardHeader
} from '@driscollsinc/style-guide-web2.0';

interface IWarehouse {
    warehouse: TWarehouse;
    loadOptions: TLoadOptions;
}

const Warehouse = ({ warehouse, loadOptions }: IWarehouse) => {
    const [errorMessage, setTheErrorMessage] = useState<Array<string> | null>(null);
    const users = warehouse.Users.map((u) => u.Email);

    return (
        <DriscollsCard className={`${style.card} ${style.warehouse} ${errorMessage !== null ? style.entityError : ''}`}>
            <DriscollsCardHeader className={style.header}>
                <WarehouseHeader loadOptions={loadOptions} name={warehouse.Name} warehouseId={warehouse.Id} />
            </DriscollsCardHeader>
            <DriscollsCardBody className={style.body}>
                <UsersList users={users} />
                {errorMessage !== null && (
                    <DriscollsTypography
                        className={style.error}
                        type={DriscollsTypographyEnum.P}
                        typographyStyle={DriscollsTypographyStyles.SUBTITLE5}
                    >{`${errorMessage}`}</DriscollsTypography>
                )}
            </DriscollsCardBody>
        </DriscollsCard>
    );
};

export default Warehouse;
