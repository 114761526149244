import React, { useState } from 'react';
import {
    ApplicationPageTitle,
    ApplicationPage,
    DriscollsVContainer,
    DriscollsHContainer,
    DriscollsSelect,
    DriscollsTranslatedValue,
    DriscollsButton,
    ButtonVariant,
    useForm,
    yupResolver
} from '@driscollsinc/style-guide-web2.0';
import styles from './Sites.module.scss';
import { NAMESPACE } from '../../../i18n';
import { SiteList } from './mockdata';
import { CreateSiteContextProvider, useCreateSiteContext } from '../../context/CreateSiteContext';
import { SiteSchema } from '../../schemas/createSite';

const CreateNewSite: React.FC = () => {
    return (
        <CreateSiteContextProvider>
            <CreateSiteContent />
        </CreateSiteContextProvider>
    );
};

const CreateSiteContent: React.FC = () => {
    const { selectedRegion, setSelectedRegion, selectedSite, setSelectedSite, selectedWarehouses, setSelectedWarehouses, handleCancel } =
        useCreateSiteContext();

    const [warehouseOptions, setWarehouseOptions] = useState([]);

    const handleRegionChange = (selectedOption: any) => {
        setSelectedRegion(selectedOption?.value);
        const selectedAssignment = SiteList.Assignments.find((assignment) => assignment.Region.Name === selectedOption?.value);
        const warehouses = selectedAssignment ? selectedAssignment.Warehouses : [];
        const options = warehouses.map((warehouse) => ({
            value: warehouse.Name,
            label: warehouse.Name
        }));
        setWarehouseOptions(options);
    };

    const handleSiteChange = (selectedOption: any) => {
        setSelectedSite(selectedOption?.value);
    };

    const handleWarehousesChange = (selectedOptions: any[]) => {
        setSelectedWarehouses(selectedOptions?.map((option) => option.value));
    };

    const {
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(SiteSchema)
    });

    const onSubmit = (data: unknown) => {
        console.log('on submit>>', data);
    };

    return (
        <ApplicationPage className={styles.qmpApplicationPage}>
            <ApplicationPageTitle
                title={{ text: 'Sites', nameSpace: NAMESPACE.SITES }}
                description={{ text: 'Assign Warehouses to a Site and a Site to a Region', nameSpace: NAMESPACE.SITES }}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <DriscollsHContainer>
                    <DriscollsSelect
                        className={styles.selectField}
                        containerClassName={styles.selectContainer}
                        name="region"
                        onChange={handleRegionChange}
                        classNamePrefix="region"
                        placeholder={<DriscollsTranslatedValue value="Region" nameSpacing={NAMESPACE.SITES} />}
                        options={SiteList.Assignments.map((assignment) => ({
                            value: assignment.Region.Name,
                            label: assignment.Region.Name
                        }))}
                        error={!selectedRegion && errors.selectedRegion?.message}
                    />
                    <DriscollsSelect
                        className={styles.selectField}
                        containerClassName={styles.selectContainer}
                        name="site"
                        classNamePrefix="site"
                        onChange={handleSiteChange}
                        placeholder={<DriscollsTranslatedValue value="Site" nameSpacing={NAMESPACE.SITES} />}
                        options={SiteList.Assignments.map((assignment) => ({
                            value: assignment.Site.Name,
                            label: assignment.Site.Name
                        }))}
                        error={!selectedSite && errors.selectedSite?.message}
                    />
                </DriscollsHContainer>
                <DriscollsVContainer>
                    <DriscollsSelect
                        className={styles.multiSelectField}
                        containerClassName={styles.multiSelectContainer}
                        isMulti={true}
                        onChange={handleWarehousesChange}
                        virtualization={true}
                        placeholder={<DriscollsTranslatedValue value="Warehouses" nameSpacing={NAMESPACE.SITES} />}
                        options={warehouseOptions}
                        error={!selectedWarehouses[0] && errors.selectedWarehouses?.message}
                    />
                </DriscollsVContainer>
                <DriscollsVContainer>
                    <DriscollsHContainer className={styles.controls}>
                        <DriscollsButton
                            variant={ButtonVariant.SECONDARY}
                            label={<DriscollsTranslatedValue nameSpacing={NAMESPACE.COMMON} value="Cancel" />}
                            onClick={handleCancel}
                        />
                        <DriscollsButton
                            label={<DriscollsTranslatedValue nameSpacing={NAMESPACE.COMMON} value="Save" />}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </DriscollsHContainer>
                </DriscollsVContainer>
            </form>
        </ApplicationPage>
    );
};

export default CreateNewSite;
