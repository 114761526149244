import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// import { withLocalize } from 'react-localize-redux';
//TODO: remove react-localize-redux

const styles = (theme) => ({
    widget: {
        '& #okta-sign-in.auth-container h2, & .number-challenge-view': {
            display: 'none'
        },
        '& #okta-sign-in .o-form .o-form-input .o-form-control': {
            borderRadius: '6px'
        },
        '& #okta-sign-in.auth-container #okta-signin-submit': {
            backgroundColor: theme.light.accent.primary,
            background: theme.light.accent.primary,
            border: 'none',
            boxShadow: '0px 4px 10px 0px ' + theme.light.header.shadow
        },
        '& #okta-sign-in.auth-container #okta-signin-submit:hover': {
            backgroundColor: theme.light.accent.brightness(-10),
            background: theme.light.accent.brightness(-10)
        },
        '& #okta-sign-in.auth-container .okta-form-input-field.focused-input': {
            borderColor: theme.light.accent.primary
        },
        [theme.darkTheme]: {
            '& #okta-sign-in.auth-container #okta-signin-submit': {
                backgroundColor: theme.dark.accent.primary,
                background: theme.dark.accent.primary,
                boxShadow: '0px 4px 10px 0px ' + theme.dark.header.shadow
            },
            '& #okta-sign-in.auth-container #okta-signin-submit:hover': {
                backgroundColor: theme.dark.accent.brightness(-10),
                background: theme.dark.accent.brightness(-10)
            },
            '& #okta-sign-in.auth-container .okta-form-input-field.focused-input': {
                borderColor: theme.dark.accent.primary
            },
            '& #okta-sign-in.auth-container.main-container': {
                backgroundColor: theme.dark.primary,
                color: theme.dark.text.secondary
            },
            '& #okta-sign-in.auth-container .okta-form-input-field': {
                backgroundColor: theme.dark.primary + ' !important'
            },
            '& #okta-sign-in .o-form label': {
                color: theme.dark.text.secondary
            },
            '& #okta-sign-in.auth-container .okta-form-input-field input': {
                color: theme.dark.text.secondary,
                '-webkit-text-fill-color': theme.dark.text.secondary,
                '-webkit-box-shadow': '0 0 0px 1000px #000 inset'
            },
            '& #okta-sign-in.auth-container .link:link': {
                color: theme.dark.text.secondary
            },
            '& #okta-sign-in .infobox': {
                backgroundColor: theme.dark.primary,
                color: theme.dark.text.secondary
            }
        }
    }
});

class DrcOktaWidget extends Component {
    constructor(props) {
        super(props);

        this.state = { activeLanguage: null };
    }
    componentDidUpdate() {
        try {
            if (this.state.activeLanguage === this.props.activeLanguage) {
                return;
            }

            if (this.widget) {
                this.widget.remove();
            }

            this.widget = new window.OktaSignIn({
                baseUrl: this.props.baseUrl,
                language: this.props.activeLanguage ? this.props.activeLanguage.code : 'en', //Default to English
                ...this.props.config
            });

            this.widget.renderEl({ el: this.node }, this.props.onSuccess, this.props.onError);
        } catch (error) {}
    }

    componentWillUnmount() {
        try {
            this.widget.remove();
        } catch (error) {}
    }

    render() {
        var { classes, className } = this.props;

        return (
            <div className={`${classes.widget} ${className}`}>
                <div ref={(node) => (this.node = node)} />
            </div>
        );
    }
}

// export default withLocalize(withStyles(styles)(DrcOktaWidget));
export default withStyles(styles)(DrcOktaWidget);
