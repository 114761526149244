import { createContext } from 'react';
import { Persona } from '../shared/protected/PersonaBaseProtect';

interface IAppContext {
    userPersona: Persona | null;
    setUserPersona: (userPersona: Persona) => void;
    isMasterDataInitialized: boolean;
    setMasterDataInitialized: (isMasterDataInitialized: boolean) => void;
    pageTitle: string | null;
    setPageTitle: (lang: string) => void;
    redirectUrl: string | null;
    setInitializeRedirectUrl: (url: string) => void;
}

const ApplicationContextProvider = createContext<IAppContext>({} as IAppContext);

export default ApplicationContextProvider;
