import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

// Basic Dialog box with dynamic button amount.
// Input properties:
// open: Type bool. Defines whether the dialog is open or closed.
// title: Type String. Defines the title of the dialog box.
// content: Type Sting. Defines the body of the dialog box.
// buttons: Type Buttons. Can handle multiple buttons. Styles must be set on the
//          input buttons
var styles = (theme) => ({
    key: {
        textAlign: 'right',
        fontWeight: 'bold',
        padding: '4px'
    },
    tooltip: {
        fontSize: '1rem'
    }
});

class DrcKeyValueTable extends React.Component {
    renderRow = (row) => {
        var { classes } = this.props;

        return (
            <tr key={row.key}>
                <td className={classes.key}>{row.key} : </td>
                <td>
                    {row.tooltip ? (
                        <Tooltip title={row.tooltip ? <React.Fragment>{row.tooltip} </React.Fragment> : null} className={classes.tooltip}>
                            <Typography style={{ cursor: 'auto' }}>{row.value}</Typography>
                        </Tooltip>
                    ) : (
                        <Typography>{row.value}</Typography>
                    )}
                </td>
            </tr>
        );
    };

    render() {
        const { children, className } = this.props;

        return (
            <table className={className}>
                <tbody>{(children || []).map((row) => this.renderRow(row))}</tbody>
            </table>
        );
    }
}

export default withStyles(styles)(DrcKeyValueTable);
