import { TUserEmail, TUsersList } from '../../types/users';

export const USERS: TUsersList = {
    Total: '2',
    Assignments: [
        {
            Id: 'r1',
            Region: {
                Id: '123',
                Name: 'QC-Iberia'
            },
            Site: {
                Id: '2',
                Name: 'Portugal'
            },
            Warehouses: [
                {
                    Id: '0001',
                    Name: 'WH-0001 Watsonville',
                    Users: [
                        {
                            Id: '1',
                            Email: 'vishal.thanvi@driscolls.com'
                        },
                        {
                            Id: '2',
                            Email: 'leonardo.gigli@driscolls.com'
                        }
                    ]
                },
                {
                    Id: '0003',
                    Name: 'WH-0003 Santa Maria',
                    Users: [
                        {
                            Id: '2',
                            Email: 'leonardo.gigli@driscolls.com'
                        }
                    ]
                }
            ]
        },
        {
            Id: 'r2',
            Region: {
                Id: '124',
                Name: 'QC-Iberia-2'
            },
            Site: {
                Id: '23',
                Name: 'Portugal-21'
            },
            Warehouses: [
                {
                    Id: '0002',
                    Name: 'WH-0002 Watsonville-2',
                    Users: [
                        {
                            Id: '5',
                            Email: 'vishal.thanvi@driscolls.com'
                        }
                    ]
                },
                {
                    Id: '0004',
                    Name: 'WH-0004 Santa Maria-4',
                    Users: [
                        {
                            Id: '2',
                            Email: 'leonardo.gigli@driscolls.com'
                        }
                    ]
                }
            ]
        }
    ]
};

export const EMAIL_LIST: TUserEmail[] = [
    {
        Id: '1',
        Email: 'vishal.thanvi@driscolls.com'
    },
    {
        Id: '2',
        Email: 'leonardo.gigli@driscolls.com'
    },
    {
        Id: '3',
        Email: 'retheesh.rama@driscolls.com'
    }
];
