import React from 'react';
import DrcButton from './DrcButton';
import { withStyles } from '@material-ui/core/styles';

var styles = (theme) => ({
    iconButton: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        minWidth: theme.spacing(5),
        borderRadius: theme.spacing(2.5)
    },
    normalBottomBorder: {
        borderBottomWidth: '1px',
        '&:hover': {
            borderBottomWidth: '1px'
        }
    }
});

class DrcIconButton extends React.Component {
    render() {
        const { style, onClick, isPrimary, isSecondary, isText, children, title, className, disabled, classes, hard, poly, line } = this.props;

        var primary = isPrimary;
        if (!isSecondary && !isText) {
            primary = true;
        }

        var addNormalMargin = false;
        if (line & !hard) {
            addNormalMargin = true;
        }

        return (
            <DrcButton
                className={`${classes.iconButton} ${addNormalMargin ? classes.normalBottomBorder : null} ${className}`}
                style={{
                    ...style
                }}
                isPrimary={primary}
                isSecondary={isSecondary}
                disabled={!!disabled}
                onClick={onClick}
                title={title}
                noStyle
                hard={hard}
                poly={poly}
                line={line}
            >
                {children}
            </DrcButton>
        );
    }
}

export default withStyles(styles)(DrcIconButton);
