import React, { createContext, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

interface CreateSiteContextProps {
  selectedRegion: string;
  setSelectedRegion: React.Dispatch<React.SetStateAction<string>>;
  selectedSite: string;
  setSelectedSite: React.Dispatch<React.SetStateAction<string>>;
  selectedWarehouses: string[];
  setSelectedWarehouses: React.Dispatch<React.SetStateAction<string[]>>;
  handleCancel: () => void;
}

export const CreateSiteContext = createContext<CreateSiteContextProps | undefined>(undefined);

export const useCreateSiteContext = () => {
  const context = useContext(CreateSiteContext);
  if (!context) {
    throw new Error('useCreateSiteContext must be used within a CreateSiteContextProvider');
  }
  return context;
};

export const CreateSiteContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedSite, setSelectedSite] = useState('');
  const [selectedWarehouses, setSelectedWarehouses] = useState<string[]>([]);

  const navigate = useNavigate();

  const handleCancel = () => {
    navigate('/demea/sites')
  };

  const contextValue: CreateSiteContextProps = {
    selectedRegion,
    setSelectedRegion,
    selectedSite,
    setSelectedSite,
    selectedWarehouses,
    setSelectedWarehouses,
    handleCancel
  };

  return <CreateSiteContext.Provider value={contextValue}>{children}</CreateSiteContext.Provider>;
};
