import React from 'react';
import DrcLegend from './DrcLegend';

class DrcChartWrapper extends React.Component {
    render() {
        return (
            <div style={this.props.style}>
                <DrcLegend>{this.props.title}</DrcLegend>
                {this.props.children}
            </div>
        );
    }
}

export default DrcChartWrapper;
