import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { IUserDetails } from '../components/appInitializer/IUserDetails';
import { IPersona } from '../components/appInitializer/IUserPersona';
import { Persona } from '../shared/protected/PersonaBaseProtect';
import React, { useMemo, useState } from 'react';
import { IShowToast } from '../types/application';
import ApplicationContext from './ApplicationContext';

const ApplicationProvider = (props: any) => {
    const [userPersona, setUserPersona] = useState<Persona>(null);
    const [isMasterDataInitialized, setMasterDataInitialized] = useState<boolean>(false);
    const [userPersonas, setUserPersonas] = useState<IPersona[]>([]);
    const [userStatus, setUserStatus] = useState<boolean>(false);
    const [basicInformation, setBasicInformation] = useState<any>(null);
    const [userProfile, setUserProfile] = useState<IUserDetails>(null);
    const [pageTitle, setPageTitle] = useState<string>(null);
    const [redirectUrl, setInitializeRedirectUrl] = useState<string>(null);
    const [landingPage, setLandingPage] = useState<string>(null);
    const [showToast, setShowToast] = useState<IShowToast>({ toastMessage: '', severity: DriscollsToastSeverity.SUCCESS });

    const value = useMemo(
        () => ({
            userPersona,
            setUserPersona,
            isMasterDataInitialized,
            setMasterDataInitialized,
            userPersonas,
            setUserPersonas,
            userStatus,
            setUserStatus,
            basicInformation,
            setBasicInformation,
            userProfile,
            setUserProfile,
            pageTitle,
            setPageTitle,
            redirectUrl,
            setInitializeRedirectUrl,
            landingPage,
            setLandingPage,
            showToast,
            setShowToast,
        }),
        [
            basicInformation,
            isMasterDataInitialized,
            landingPage,
            pageTitle,
            redirectUrl,
            userPersona,
            userPersonas,
            userProfile,
            userStatus,
            showToast
        ]
    );
    return <ApplicationContext.Provider value={value}>{props.children}</ApplicationContext.Provider>;
};

export default ApplicationProvider;
