// DrcMaintenancePage.js
// This component manages data correlated to specific data type used in the
// application. It can edit, add, and delete data from the DB through the
// functions passed in.

// Props:
// type: Arg type String. The type represents the Data Type itself that is being
//      maintained by this page. It is used for titles and labels in the component.
// columns(recommended): Arg type Array of Objects. Objects must contain key and name
//      pair correlating to the Data types key and the display name for that key. There
//      is also an optional prop called validationType that denotes the validation on
//      the maintenance input options. Right now number is supported and by default a
//      value is treated as type string.
//      ex: [{key:"CriteriaOptions", name:"Berry Type"}, {key:"CriteriaOrder", name:"Type Id", validationType:"number"}]
// data: Arg type Array of objects. Contains the values currently contained in the
//      application. This is the data that will be displayed in the table and edited
//      in the maintenance page.
//      ex: [{CriteriaOptions: "Straw", CriteriaOrder: "1"}, {CriteriaOptions: "Rasp", CriteriaOrder: "2"}]
// onAdd: Arg type Function. Function that handles adding a new value for the data type.
// onEdit: Arg type Function. Function that handles editing a value for the data type.
// onDelete: Arg type Function. Function that handles deleting a value for the data type.
// readOnly(default=false): Arg type Boolean. If set to true, hides editing features of the
//      maintenance page.
// addDisabled(default=false): Arg type Boolean. This property disables add functions in DrcMaintenance.
// editDisabled(default=false): Arg type Boolean. This property disables edit functions in DrcMaintenance.

import React, { Component } from 'react';
import DrcMain from '../Components/DrcMain';
import DrcPanel from '../Components/DrcPanel';
// import DrcGrid from '../Components/DrcGrid';
import DrcDialog from '../Components/DrcDialog';
import DrcButton from '../Components/DrcButton';
import DrcInput from '../Components/DrcInput';
import DrcSwitch from '../Components/DrcSwitch';
import DrcSelect from '../Components/DrcSelect';
import DrcCheckbox from '../Components/DrcCheckbox';
import DrcDatePicker from '../Components/DrcDatePicker';
import DrcTimePicker from '../Components/DrcTimePicker';
import DrcKeyValueTable from '../Components/DrcKeyValueTable';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForever from '@material-ui/icons/DeleteForever';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { withStyles } from '@material-ui/core/styles';
// import { Toolbar, Data } from 'react-data-grid-addons';
import { DuExcelUtilities, DuThemeUtilities, DuValidationUtilities } from 'driscolls-react-utilities';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const styles = (theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '100%',
            width: '100%'
        }
    },
    legendTitle: {
        fontSize: '1.5rem'
    },
    actionButton: {
        minWidth: '40px',
        display: 'block',
        margin: '0px !important',
        padding: '9px 8px 3px'
    },
    actionButtonError: {
        color: theme.light.text.errorTitle,
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    },
    required: {
        color: DuThemeUtilities.DefaultColors.primary.red
    },
    radio: {
        color: theme.light.accent.primary,
        [theme.darkTheme]: {
            color: theme.dark.accent.primary
        }
    },
    gridContainer: {
        clear: 'both'
    },
    gridHeadline: {
        marginTop: 0,
        float: 'left',
        [theme.breakpoints.down('xs')]: {
            float: 'none'
        }
    }
});

// const selectors = Data.Selectors;

const allowedStringValuesRegex = RegExp(/[,?\/#!$%\^&\*;:{}=_`"~()]/);
const allowedNumberValuesRegex = RegExp(/^[0-9]*$/);
const allowedDecimalValuesRegex = RegExp(/^[0-9]*(\.[0-9]*)?$/);

export const MAINTENANCE_PAGE_CONSTANTS = {
    ORIENTATION_HORIZONTAL: 0,
    ORIENTATION_VERTICAL: 1,
    ACTION_HIDDEN: 0,
    ACTION_FIRST_FROZEN: 1,
    ACTION_LAST_FROZEN: 2,
    ACTION_FIRST: 3,
    ACTION_LAST: 4
};

const defaultColumnProperties = {
    resizable: true,
    filterable: true
};

const DEFAULT_TEXT = {
    PageTitle: 'Maintenance',
    AddBtn: 'Add',
    ExportBtn: 'Download',
    CancelBtn: 'Cancel',
    SaveBtn: 'Save',
    DeleteBtn: 'Delete',
    ConfirmAcceptBtn: 'Accept',
    ConfirmRejectBtn: 'Oops, Just Kidding'
};

const DEFAULT_OPTIONS = {
    EnableCheckBoxDelete: true,
    EnableDelete: true,
    EnableEdit: true,
    EnableAdd: true,
    EnableExport: true,
    Orientation: MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_HORIZONTAL,
    ActionColumnSetting: MAINTENANCE_PAGE_CONSTANTS.ACTION_LAST_FROZEN,
    ShowCount: true,
    OverrideAdd: null,
    OverrideEdit: null
};

class DrcPageMaintenance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: false,
            dialogEditOpen: false,
            dialogAddNewOpen: false,
            dialogText: '',
            dialogConfirm: () => {},
            addEditText: 'Add New ' + this.props.type,
            type: '',
            oldValue: {},
            isEdit: false,
            isEnabled: false,
            editedValue: {},
            helperText: [],
            editArray: [],
            readOnlyArray: [],
            errorCount: 0,
            filters: {},
            textOptions: { ...DEFAULT_TEXT },
            settings: { ...DEFAULT_OPTIONS },
            columns: [],
            selectedRecords: []
        };

        this.onDialogYes = this.onDialogYes.bind(this);
        this.onDialogNo = this.onDialogNo.bind(this);
        this.getCellActions = this.getCellActions.bind(this);
        this.onAddEditYes = this.onAddEditYes.bind(this);
        this.onAddEditNo = this.onAddEditNo.bind(this);
        this.openAddNew = this.openAddNew.bind(this);
        this.export = this.export.bind(this);
        this.onAddNewYes = this.onAddNewYes.bind(this);
        this.onAddNewNo = this.onAddNewNo.bind(this);
        this.buildEditArray = this.buildEditArray.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.allFieldsFilled = this.allFieldsFilled.bind(this);
        this.onSelectValueChange = this.onSelectValueChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
    }

    componentDidMount() {
        this.onLoad();
    }

    componentDidUpdate() {
        this.onLoad();
    }

    onLoad = () => {
        let stateChange = {};
        let optionsFromProps = {};

        if (typeof this.props.disableDelete === 'boolean') {
            optionsFromProps.EnableDelete = !this.props.readOnly && !this.props.disableDelete;
        }

        if (typeof this.props.disableEdit === 'boolean') {
            optionsFromProps.EnableEdit = !this.props.readOnly && !this.props.disableEdit;
        }

        if (typeof this.props.addBtn === 'boolean') {
            optionsFromProps.EnableAdd = this.props.addBtn;
        }

        if (typeof this.props.exportAllowed === 'boolean') {
            optionsFromProps.EnableExport = this.props.exportAllowed;
        }

        if (typeof this.props.orientation === 'string') {
            optionsFromProps.Orientation = this.props.orientation;
        }

        let settings = { ...DEFAULT_OPTIONS, ...optionsFromProps, ...(this.props.settings || {}) };
        const readOnly = !settings.EnableAdd && settings.EnableDelete && !settings.EnableEdit && !settings.EnableCheckBoxDelete.access;

        if (!this.isEquivalent(this.state.settings, settings)) {
            stateChange.settings = settings;
        }

        let textOptions = { ...DEFAULT_TEXT, ...(this.props.textOptions || {}) };

        if (!this.isEquivalent(this.state.textOptions, textOptions)) {
            stateChange.textOptions = textOptions;
        }

        if (Object.getOwnPropertyNames(stateChange).length > 0) {
            this.setState(stateChange);
        }
    };

    //TODO: Make this into a generic utility we can use elsewhere
    isEquivalent = (a, b) => {
        // Create arrays of property names
        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);

        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length != bProps.length) {
            return false;
        }

        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];

            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        // If we made it this far, objects
        // are considered equivalent
        return true;
    };

    handleFilterChange(filter) {
        var filters = this.state.filters;
        const newFilters = { ...filters };
        const columns = this.getColumns();

        const column = columns.find((col) => {
            return col.key === filter.column.key;
        });

        const key = column && column.filterKey ? column.filterKey : filter.column.key;

        if (filter.filterTerm) {
            newFilters[key] = filter;
        } else {
            delete newFilters[key];
        }
        return newFilters;
    }

    componentDidUpdate() {
        if (this.state.editArray.length < 1) {
            this.buildEditArray();
        }
    }

    setSelectOption = (optionValue, isEdit, isMulti, editIsMultiDisabled, selectOptions, key) => {
        if (optionValue) {
            if (isEdit && isMulti && editIsMultiDisabled) {
                return selectOptions.find((opt) => opt.value === optionValue);
            } else if (isMulti) {
                return this.state.editedValue[key];
            } else {
                return selectOptions.find((opt) => opt.value === optionValue);
            }
        } else return null;
    };

    async buildEditArray() {
        const that = this;
        var returnArray = [];
        var returnReadOnlyArray = [];
        var columns = this.getColumns();
        var orient = this.state.settings.Orientation;

        var isDisabled = that.props.editDisabled && that.state.isEdit ? true : that.props.addDisabled && !that.state.isEdit ? true : false;

        columns.forEach(function(c, i) {
            var columnDisabled = c.editDisabled && that.state.isEdit ? true : c.addDisabled && !that.state.isEdit ? true : false;

            if (c.isDisabled === true) {
                let displayValue;
                if (!that.state.editedValue[c.key] || that.state.editedValue[c.key] !== c.displayValue) {
                    let editedValue = { ...that.state.editedValue };
                    c.displayValue = editedValue[c.key];
                    displayValue = c.displayValue;
                } else {
                    displayValue = that.state.editedValue[c.key];
                }

                if (!c.isHidden) {
                    returnReadOnlyArray.push(
                        <div className="col-sm-6" key={i}>
                            <DrcKeyValueTable>{[{ key: c.name, value: displayValue }]}</DrcKeyValueTable>
                        </div>
                    );
                }
            } else if (c.inputType === 'Select') {
                var optionValue = that.state.editedValue[c.key] || '';
                var option = that.setSelectOption(optionValue, that.state.isEdit, c.isMulti, c.editIsMultiDisabled, c.selectOptions, c.key);

                var { name, key, selectOptions, isRequired, isMulti, maxMenuHeight, ...other } = c;

                if (c.defaultValue && that.state.editedValue[c.key] === undefined) {
                    let editedValue = { ...that.state.editedValue };
                    editedValue[key] = c.defaultValue.value;
                    that.setState({ editedValue });
                }

                returnArray.push(
                    <DrcSelect
                        label={name}
                        name={key}
                        options={selectOptions}
                        value={option || c.defaultValue}
                        key={i}
                        isDisabled={isDisabled || columnDisabled}
                        index={that.props.index}
                        onChange={(option, type) => that.onSelectValueChange(option, type, that.state.isEdit ? (c.editIsMultiDisabled ? false : !!isMulti) : !!isMulti)}
                        InputLabelProps={{ shrink: true }}
                        className={orient === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_HORIZONTAL ? 'col-xs-12 col-sm-6' : 'col-xs-12'}
                        style={{ marginRight: 0 }}
                        required={isRequired}
                        isMulti={that.state.isEdit ? (c.editIsMultiDisabled ? false : !!isMulti) : !!isMulti}
                        maxMenuHeight={maxMenuHeight}
                        {...other}
                    />
                );
            } else if (c.inputType === 'Switch') {
                var { key, isRequired, name, ...other } = c;

                if (c.defaultValue && that.state.editedValue[c.key] === undefined) {
                    let editedValue = { ...that.state.editedValue };
                    editedValue[key] = c.defaultValue;
                    that.setState({ editedValue });
                }

                returnArray.push(
                    <div className={orient === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_VERTICAL ? 'col-xs-12' : 'col-xs-12 col-sm-6'} key={i}>
                        <DrcSwitch
                            checked={that.state.editedValue[key] === undefined ? c.defaultValue : that.state.editedValue[key]}
                            value={that.state.editedValue[key]}
                            disabled={isDisabled || columnDisabled}
                            onChange={() => that.handleSwitchChange(c.key, c.defaultValue)}
                            style={{ margin: 'auto', padding: 'auto' }}
                            required={isRequired}
                            {...other}
                        >
                            {name}
                        </DrcSwitch>
                    </div>
                );
            } else if (c.inputType === 'Checkbox') {
                var { key, isRequired, name, ...other } = c;

                returnArray.push(
                    <div className={orient === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_VERTICAL ? 'col-xs-12' : 'col-xs-12 col-sm-6'} key={i}>
                        <FormControlLabel
                            control={
                                <DrcCheckbox
                                    checked={that.state.editedValue[key]}
                                    value={that.state.editedValue[key]}
                                    disabled={isDisabled || columnDisabled}
                                    onChange={() => that.handleSwitchChange(key)}
                                    style={{ margin: 'auto', padding: 'auto' }}
                                    required={isRequired}
                                    {...other}
                                ></DrcCheckbox>
                            }
                            label={
                                <React.Fragment>
                                    {name}
                                    {isRequired ? <span className={this.props.classes.required}>*</span> : null}
                                </React.Fragment>
                            }
                        />
                    </div>
                );
            } else if (c.inputType === 'Radio') {
                let checked = true;
                let radioButtons = [];
                var { key, isRequired, name, options, ...other } = c;

                for (let i = 0; i < c.options.length; i++) {
                    if (that.state.editedValue[key]) {
                        if (!that.state.editedValue[key] || that.state.editedValue[key] === options[i]) {
                            checked = true;
                        } else {
                            checked = false;
                        }
                    } else {
                        if (i !== 0) {
                            checked = false;
                        }
                    }

                    radioButtons.push(
                        <FormControlLabel
                            key={'rad_' + i}
                            name={name}
                            value={that.state.editedValue[key]}
                            control={
                                <Radio
                                    checked={checked}
                                    disabled={isDisabled || columnDisabled}
                                    onChange={() => that.handleRadioButtonChange(key, options[i])}
                                    required={isRequired}
                                    className={that.props.classes.radio}
                                    {...other}
                                />
                            }
                            label={options[i]}
                        />
                    );
                }

                if (!that.state.editedValue[key]) {
                    let editedValue = { ...that.state.editedValue };
                    editedValue[key] = options[0];
                    that.setState({ editedValue });
                }

                returnArray.push(
                    <div className={orient === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_VERTICAL ? 'col-xs-12' : 'col-xs-12 col-sm-6'} key={i}>
                        {radioButtons}
                    </div>
                );
            } else if (c.inputType === 'Date') {
                var { key, isRequired, name, ...other } = c;

                if (c.defaultValue && that.state.editedValue[c.key] === undefined) {
                    let editedValue = { ...that.state.editedValue };
                    editedValue[key] = c.defaultValue;
                    that.setState({ editedValue });
                }

                returnArray.push(
                    <div className={orient === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_VERTICAL ? 'col-xs-12' : 'col-xs-12 col-sm-6'} key={i}>
                        <DrcDatePicker
                            clearable
                            label={name}
                            name={key}
                            disabled={isDisabled || columnDisabled}
                            InputLabelProps={{ shrink: true }}
                            onChange={(val) => that.handleDateChange(key, val)}
                            selectedDate={that.state.editedValue[key] || c.defaultValue}
                            required={isRequired}
                            shouldDisableDate={(date) => that.shouldDisableDate(date, key)}
                            {...other}
                        />
                    </div>
                );
            } else if (c.inputType === 'Time') {
                var { key, isRequired, name, ...other } = c;

                if (c.defaultValue && that.state.editedValue[c.key] === undefined) {
                    let editedValue = { ...that.state.editedValue };
                    editedValue[key] = c.defaultValue;
                    that.setState({ editedValue });
                }

                returnArray.push(
                    <div className={orient === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_VERTICAL ? 'col-xs-12' : 'col-xs-12 col-sm-6'} key={i}>
                        <DrcTimePicker
                            clearable
                            label={name}
                            name={key}
                            disabled={isDisabled || columnDisabled}
                            InputLabelProps={{ shrink: true }}
                            onChange={(value) => that.handleTimeChange(key, value)}
                            value={that.state.editedValue[key] || c.defaultValue}
                            required={isRequired}
                            {...other}
                        />
                    </div>
                );
            } else {
                let type = (c.inputType || 'text').toLowerCase();
                var { key, isRequired, name, ...other } = c;

                returnArray.push(
                    <div className={orient === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_HORIZONTAL ? 'col-xs-12 col-sm-6' : 'col-xs-12'} key={i}>
                        <DrcInput
                            label={name}
                            disabled={isDisabled || columnDisabled}
                            name={key}
                            value={that.state.editedValue[key] || ''}
                            onChange={(evt) => that.handleAddEditChange(evt, i)}
                            helperText={that.state.helperText[i]}
                            InputLabelProps={{ shrink: true }}
                            style={{ marginRight: 0 }}
                            required={isRequired}
                            type={type}
                            {...other}
                        />
                    </div>
                );
            }
        });

        if (returnArray.length !== 0 || returnReadOnlyArray.length !== 0) {
            this.setState({
                editArray: returnArray,
                readOnlyArray: returnReadOnlyArray
            });
        }
    }

    onDialogYes() {
        if (this.state.isEdit) {
            this.state.dialogConfirm(this.state.oldValue, this.state.editedValue);
        } else {
            this.state.dialogConfirm(this.state.editedValue);
        }

        this.setState(
            {
                dialogOpen: false,
                dialogConfirm: () => {},
                addEditText: 'Add New ' + this.props.type,
                type: '',
                oldValue: {},
                editedValue: {},
                isEnabled: false,
                isEdit: false,
                helperText: [],
                selectedRecords: []
            },
            this.buildEditArray
        );
    }

    onDialogNo() {
        this.setState(
            {
                dialogOpen: false,
                dialogConfirm: () => {},
                addEditText: 'Add New ' + this.props.type,
                type: '',
                oldValue: {},
                editedValue: {},
                isEnabled: false,
                isEdit: false,
                helperText: []
            },
            this.buildEditArray
        );
    }

    handleSwitchChange(name, defaultValue) {
        var retEditArray = { ...this.state.editedValue };
        if (this.state.editedValue[name]) {
            retEditArray[name] = false;
        } else {
            if (this.state.editedValue[name] === undefined && defaultValue) {
                retEditArray[name] = false;
            } else retEditArray[name] = true;
        }

        var submitEnabled = this.allFieldsFilled();
        this.setState({ editedValue: retEditArray, isEnabled: submitEnabled }, this.buildEditArray);
    }

    handleRadioButtonChange = (name, type) => {
        var retEditArray = { ...this.state.editedValue };
        retEditArray[name] = type;

        var submitEnabled = this.allFieldsFilled();
        this.setState({ editedValue: retEditArray, isEnabled: submitEnabled }, this.buildEditArray);
    };

    handleDateChange = (name, value) => {
        var retEditArray = { ...this.state.editedValue };
        retEditArray[name] = value;

        var submitEnabled = this.allFieldsFilled();
        this.setState({ editedValue: retEditArray, isEnabled: submitEnabled }, this.buildEditArray);
    };

    shouldDisableDate = (date, key) => {
        let column = this.props.columns.find((item) => item.key === key);

        if (!column || !column.enabledDays) {
            return false;
        }

        let index = column.enabledDays.findIndex((day) => day === date.getDay());

        return index < 0;
    };

    handleTimeChange = (name, value) => {
        var retEditArray = { ...this.state.editedValue };
        retEditArray[name] = value ? value.getTime() || '' : '';

        var submitEnabled = this.allFieldsFilled();
        this.setState({ editedValue: retEditArray, isEnabled: submitEnabled }, this.buildEditArray);
    };

    handleAddEditChange = (event, i) => {
        const name = event.target.name;
        if (this.state.editedValue[name] != event.target.value) {
            var helperText = this.state.helperText;
            var retEdit = this.state.editedValue;
            var validationType = this.props.columns[i].validationType;

            if (!validationType) {
                if (this.props.columns[i].isRequired) {
                    validationType = 'required';
                }
            }

            var helpText = '';
            switch (this.props.columns[i].validationType || this.props.columns[i].inputType) {
                case 'number':
                    if (!allowedNumberValuesRegex.test(event.target.value) || isNaN(Number(event.target.value))) {
                        helpText = 'Please Remove Illegal Characters';
                    } else {
                        if (!isNaN(this.props.columns[i].minValue) && Number(event.target.value) < this.props.columns[i].minValue) {
                            helpText = 'Please Use a Larger Value';
                        } else if (!isNaN(this.props.columns[i].maxValue) && Number(event.target.value) > this.props.columns[i].maxValue) {
                            helpText = 'Please Use a Smaller Value';
                        } else {
                            helpText = this.validateTextLength(event, i);
                        }
                    }
                    break;
                case 'decimal':
                    if (!allowedDecimalValuesRegex.test(event.target.value) || isNaN(Number(event.target.value))) {
                        helpText = 'Please Remove Illegal Characters';
                    } else {
                        if (!isNaN(this.props.columns[i].minValue) && Number(event.target.value) < this.props.columns[i].minValue) {
                            helpText = 'Please Use a Larger Value';
                        } else if (!isNaN(this.props.columns[i].maxValue) && Number(event.target.value) > this.props.columns[i].maxValue) {
                            helpText = 'Please Use a Smaller Value';
                        } else {
                            helpText = this.validateTextLength(event, i);
                        }
                    }
                    break;
                case 'required':
                    if (event.target.value === '') {
                        helpText = 'This field is required';
                    } else {
                        helpText = this.validateTextLength(event, i);
                    }
                    break;
                case 'regex':
                    if (!this.props.columns[i].regex.test(event.target.value)) {
                        helpText = this.props.columns[i].regexDescription || 'Please Correct Input';
                    } else {
                        helpText = this.validateTextLength(event, i);
                    }
                    break;
                case 'email':
                    if (!DuValidationUtilities.EMAIL_REGEXP.test(event.target.value)) {
                        helpText = this.props.columns[i].regexDescription || 'Please Add a Valid Email';
                    } else {
                        helpText = this.validateTextLength(event, i);
                    }
                    break;
                case 'userId':
                    if (!DuValidationUtilities.USER_ID_REGEX.test(event.target.value)) {
                        helpText = this.props.columns[i].regexDescription || 'Please Add a Valid User Id';
                    } else {
                        helpText = this.validateTextLength(event, i);
                    }
                    break;
                default:
                    if (allowedStringValuesRegex.test(event.target.value)) {
                        helpText = 'Please Remove Illegal Characters';
                    } else {
                        helpText = this.validateTextLength(event, i);
                    }
                    break;
            }

            helperText[i] = helpText;

            retEdit[name] = event.target.value;
            var newErrorCount = helperText.filter((ht) => ht !== '').length;
            var submitEnabled = this.allFieldsFilled() && newErrorCount <= 0;
            this.setState({ editedValue: retEdit, helperText: helperText, errorCount: newErrorCount, isEnabled: submitEnabled }, this.buildEditArray);
        }
    };

    validateTextLength = (event, i) => {
        if (!isNaN(this.props.columns[i].minLength) && (event.target.value || '').length < this.props.columns[i].minLength) {
            return 'Required Length Too Small';
        } else if (!isNaN(this.props.columns[i].maxLength) && (event.target.value || '').length > this.props.columns[i].maxLength) {
            return 'Required Length Too Large';
        }

        return '';
    };

    onSelectValueChange(option, nameObj, isMulti) {
        const name = nameObj.name;
        var retEdit = this.state.editedValue;
        if (isMulti) {
            retEdit[name] = option;
        } else if (!this.state.editedValue[name] || this.state.editedValue[name].value != option.value) {
            //todo: allow empty values or null values
            //see which one would make more sense
            retEdit[name] = option.value;
        }
        var submitEnabled = this.allFieldsFilled();
        this.setState({ editedValue: retEdit, isEnabled: submitEnabled }, this.buildEditArray);
    }

    allFieldsFilled = () => {
        var saveAllowed = true;
        var editSaveAllowed = false;
        const that = this;

        this.props.columns.forEach((value) => {
            if ((that.state.editedValue[value.key] === '' || that.state.editedValue[value.key] === undefined) && value.isRequired) {
                saveAllowed = false;
            }

            if (that.state.isEdit) {
                if (that.state.editedValue[value.key] === that.state.oldValue[value.key] && value.isRequired && that.state.editedValue[value.key]) {
                    saveAllowed = false;
                } else {
                    if ((that.state.editedValue[value.key] === '' || that.state.editedValue[value.key] === undefined) && value.isRequired) {
                        editSaveAllowed = false;
                    } else {
                        if (!value.isDisabled) editSaveAllowed = true;
                    }
                }
            }
        });

        return editSaveAllowed || saveAllowed;
    };

    onAddEditYes() {
        this.setState({
            dialogEditOpen: false
        });
        var dialogText = '';
        if (this.state.helperText > 0) {
            return;
        } else if (this.state.isEdit) {
            if (typeof this.state.editedValue === 'object') {
                dialogText = [
                    <div style={{ fontSize: 20, marginBottom: 10 }}>Are you sure you want to change {this.props.type}? </div>,
                    <div className="row">
                        {/* <DrcGrid className="col-sm-12" rows={[this.state.oldValue]} columns={this.props.columns} minHeight={90} hideCount={true} /> */}
                        <ArrowDownward className="col-sm-12" style={{ fontSize: 70 }} />
                        {/* <DrcGrid
                            className="col-sm-12"
                            rows={this.props.gridDataFormatter ? this.props.gridDataFormatter([this.state.editedValue]) : [this.state.editedValue]}
                            columns={this.props.columns}
                            minHeight={90}
                            hideCount={true}
                        /> */}
                    </div>
                ];
            } else {
                dialogText = 'Are you sure you want to change ' + this.props.type + ' type: "' + this.state.oldValue + '" to "' + this.state.editedValue + '"?';
            }
        } else {
            if (typeof this.state.editedValue === 'object') {
                dialogText = [
                    <div style={{ fontSize: 20, marginBottom: 10 }}>Are you sure you want to add new {this.props.type}? </div>,
                    // <DrcGrid rows={[this.state.editedValue]} columns={this.props.columns} minHeight={90} hideCount={true} />
                ];
            } else {
                dialogText = 'Are you sure you want to add ' + this.props.type + ' type: "' + this.state.editedValue + '"?';
            }
        }

        this.setState({
            dialogOpen: true,
            dialogText: dialogText,
            dialogConfirm: this.state.isEdit ? this.props.onEdit : this.props.onAdd,
            type: this.props.type,
            helperText: []
        });
    }

    onAddEditNo() {
        this.setState(
            {
                dialogEditOpen: false,
                dialogConfirm: () => {},
                addEditText: 'Add New ' + this.props.type,
                type: '',
                oldValue: {},
                isEnabled: false,
                isEdit: false,
                editedValue: {},
                helperText: []
            },
            () => this.buildEditArray()
        );
    }

    updateSelectedRecords = (checked, row) => {
        let records = [...this.state.selectedRecords];
        if (checked) {
            row.isSelected = checked;
            records.push(row);
        }
        // remove from selected records
        else {
            row.isSelected = false;
            let index = (records || []).findIndex((record) => record[this.state.settings.EnableCheckBoxDelete.key] === row[[this.state.settings.EnableCheckBoxDelete.key]]);
            if (index !== -1) {
                records.splice(index, 1);
            }
            console.log(index);
        }
        this.setState({ selectedRecords: records, row });
    };
    handleCheckBoxChange = (event, row) => {
        this.updateSelectedRecords(event.target.checked, row);
    };

    getCellActions(column, row) {
        var actions = [];

        if (this.state.settings.EnableCheckBoxDelete.access) {
            actions.push({
                icon: (
                    <DrcCheckbox
                        className={`${this.props.classes.actionButton} ${this.props.classes.actionButtonError}`}
                        checked={row.isSelected}
                        onChange={(event) => this.handleCheckBoxChange(event, row)}
                    />
                ),
                callback: () => {
                    this.forceUpdateList(100);
                }
            });
        }

        if (this.state.settings.EnableDelete) {
            actions.push({
                icon: (
                    <DrcButton size="small" className={`${this.props.classes.actionButton} ${this.props.classes.actionButtonError}`}>
                        <DeleteForever />
                    </DrcButton>
                ),
                callback: () => {
                    this.setState({
                        dialogOpen: true,
                        dialogText: [
                            <div style={{ fontSize: 20, marginBottom: 10 }}>Are you sure you want to delete {this.props.type}?</div>,
                            // <DrcGrid rows={[row]} columns={this.props.columns} minHeight={90} hideCount={true} />
                        ],
                        dialogConfirm: this.props.onDelete,
                        addEditText: 'Add New ' + this.props.type,
                        type: this.props.type,
                        oldValue: { ...row },
                        editedValue: { ...row },
                        isEdit: false,
                        helperText: []
                    });
                }
            });
        }

        if (this.state.settings.EnableEdit) {
            actions.push({
                icon: (
                    <DrcButton size="small" className={this.props.classes.actionButton}>
                        <EditIcon />
                    </DrcButton>
                ),
                callback: () => {
                    this.state.settings.OverrideEdit
                        ? this.state.settings.OverrideEdit(row)
                        : this.setState(
                              {
                                  dialogEditOpen: true,
                                  addEditText: 'Edit Type: ' + this.props.type,
                                  type: this.props.type,
                                  oldValue: { ...row },
                                  editedValue: { ...row },
                                  isEnabled: false,
                                  isEdit: true,
                                  helperText: []
                              },
                              () => this.buildEditArray()
                          );
                }
            });
        }

        const cellActions = { actions: actions }[column.key];

        return cellActions;
    }

    openAddNew() {
        this.setState(
            {
                dialogAddNewOpen: true
            },
            () => this.buildEditArray()
        );
    }

    export() {
        DuExcelUtilities.Write(this.props.type + 'Maintenance.xlsx', this.getColumns(), this.getRows(this.props.data, this.state.filters));
    }

    onAddNewYes() {
        this.setState({
            dialogAddNewOpen: false
        });

        var dialogText = '';

        if (this.state.errorCount > 0) {
            return;
        } else {
            if (typeof this.state.editedValue === 'object') {
                dialogText = [
                    <div style={{ fontSize: 20, marginBottom: 10 }}>Are you sure you want to add new {this.props.type}? </div>,
                    // <DrcGrid
                    //     rows={this.props.gridDataFormatter ? this.props.gridDataFormatter([this.state.editedValue]) : [this.state.editedValue]}
                    //     columns={this.props.columns}
                    //     minHeight={90}
                    //     hideCount={true}
                    // />
                ];
            } else {
                dialogText = 'Are you sure you want to add new' + this.props.type + this.state.editedValue + '"?';
            }
        }

        this.setState({
            dialogOpen: true,
            dialogText: dialogText,
            dialogConfirm: this.state.isEdit ? this.props.onEdit : this.props.onAdd,
            type: this.props.type,
            helperText: []
        });
    }

    onAddNewNo() {
        this.setState(
            {
                dialogAddNewOpen: false,
                dialogConfirm: () => {},
                addEditText: 'Add New ' + this.props.type,
                type: '',
                oldValue: {},
                isEnabled: false,
                isEdit: false,
                editedValue: {},
                helperText: []
            },
            () => this.buildEditArray()
        );
    }

    deleteConfirm() {
        this.setState({
            dialogOpen: true,
            dialogText: [
                <div style={{ fontSize: 20, marginBottom: 10 }}>Are you sure you want to delete {this.props.type}?</div>,
                // <DrcGrid rows={this.state.selectedRecords} columns={this.props.columns} minHeight={90} hideCount={true} />
            ],
            dialogConfirm: this.props.onCheckboxDelete,
            addEditText: 'Add New ' + this.props.type,
            type: this.props.type,
            oldValue: [...this.state.selectedRecords],
            editedValue: [...this.state.selectedRecords],
            isEdit: false,
            helperText: []
        });
    }

    getColumns() {
        var returnValue = [];
        if (this.props.columns === undefined) {
            Object.keys(this.props.data[0] || []).forEach(function(c) {
                returnValue.push({
                    key: c,
                    name: c,
                    ...defaultColumnProperties
                });
            });
        } else {
            this.props.columns.forEach(function(c) {
                returnValue.push(c);
            });
        }
        return returnValue;
    }

    getRows(rows, filters) {
        return rows;
        // return selectors.getRows({ rows, filters });
    }

    getActionWidth = (enableEdit, enableDelete, enableCheckboxDelete) => {
        // all 3 action buttons
        if (enableEdit && enableDelete && enableCheckboxDelete) return 130;
        // any 2 action buttons
        else if ((enableEdit && enableDelete) || (enableDelete && enableCheckboxDelete) || (enableEdit && enableCheckboxDelete)) return 90;
        // any one action button
        else if (enableEdit || enableDelete || enableCheckboxDelete) return 70;
    };

    forceUpdateList = (timeout = 0) => {
        setTimeout(() => {
            var el = document.querySelector('.react-grid-Canvas');
            if (!el) {
                return;
            }
            var left = el.scrollLeft;
            var top = el.scrollTop;
            el.scroll(left + 1, top + 1);
            el.scroll(left - 1, top - 1);
            el.scroll(left, top);
        }, timeout);
    };

    render() {
        const { data, type, children, classes, fullWidth, className, getValidFilterValues, customButtons } = this.props;
        const { filters, textOptions, settings } = this.state;
        const readOnly = !settings.EnableAdd && !settings.EnableDelete && !settings.EnableEdit;

        const filteredRows = this.getRows(data, filters);
        let columns = this.getColumns();
        columns = columns.map((c) => ({ ...defaultColumnProperties, ...c }));

        if (!readOnly && settings.ActionColumnSetting !== MAINTENANCE_PAGE_CONSTANTS.ACTION_HIDDEN) {
            let actionWidth = this.getActionWidth(settings.EnableEdit, settings.EnableDelete, settings.EnableCheckBoxDelete.access);

            if (settings.ActionColumnSetting === MAINTENANCE_PAGE_CONSTANTS.ACTION_FIRST_FROZEN) {
                columns = [{ key: 'actions', name: 'Actions', width: actionWidth, frozen: true }, ...columns];
            } else if (settings.ActionColumnSetting === MAINTENANCE_PAGE_CONSTANTS.ACTION_LAST_FROZEN) {
                let frozenColumns = columns.filter((c) => !!c.frozen) || [];
                let normalColumns = columns.filter((c) => !c.frozen) || [];

                columns = [...frozenColumns, { key: 'actions', name: 'Actions', width: actionWidth, frozen: true }, ...normalColumns];
            } else if (settings.ActionColumnSetting === MAINTENANCE_PAGE_CONSTANTS.ACTION_FIRST) {
                let frozenColumns = columns.filter((c) => !!c.frozen) || [];
                let normalColumns = columns.filter((c) => !c.frozen) || [];

                columns = [...frozenColumns, { key: 'actions', name: 'Actions', width: actionWidth }, ...normalColumns];
            } else if (settings.ActionColumnSetting === MAINTENANCE_PAGE_CONSTANTS.ACTION_LAST) {
                columns = [...columns, { key: 'actions', name: 'Actions', width: actionWidth }];
            }
        }

        // refresh the selectedrecords on new selection
        if (this.props.refresh) {
            this.setState({ selectedRecords: [] });
            this.props.updateRefresh();
        }

        return (
            <React.Fragment>
                <DrcMain maxWidth={fullWidth ? '100%' : null} transparent className={`${classes.main} ${className}`}>
                    <DrcPanel maxWidth={'100%'}>
                        <div className="gridHeader">
                            <h1 className={classes.gridHeadline}>
                                {type} {textOptions.PageTitle}
                            </h1>
                            {settings.EnableAdd ? (
                                <DrcButton style={{ marginTop: 0 }} isPrimary onClick={settings.OverrideAdd ? settings.OverrideAdd : this.openAddNew} floatRight>
                                    {textOptions.AddBtn}
                                </DrcButton>
                            ) : null}
                            {settings.EnableExport ? (
                                <DrcButton style={{ marginTop: 0 }} isSecondary onClick={this.export} floatRight>
                                    {textOptions.ExportBtn}
                                </DrcButton>
                            ) : null}
                            {settings.EnableCheckBoxDelete.access && !settings.EnableDelete ? (
                                <DrcButton style={{ marginTop: 0 }} isSecondary onClick={this.deleteConfirm} floatRight disabled={!this.state.selectedRecords.length}>
                                    {textOptions.DeleteBtn}
                                </DrcButton>
                            ) : null}
                            {customButtons}
                        </div>
                        <div className={`row ${classes.gridContainer}`}>
                            {/* <DrcGrid
                                fullHeightOffset={this.props.fullHeightOffset}
                                className={
                                    classes.grid +
                                    ' ' +
                                    (readOnly ? 'col-xs-12' : settings.Orientation === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_HORIZONTAL ? 'col-xs-12' : 'col-xs-12  col-sm-8')
                                }
                                rows={filteredRows}
                                columns={columns}
                                getCellActions={this.getCellActions}
                                toolbar={<Toolbar enableFilter={true} />}
                                onAddFilter={(filter) => this.setState({ filters: this.handleFilterChange(filter, this.state.filters) })}
                                onClearFilters={() => this.setState({ filters: {} })}
                                hideCount={!settings.ShowCount}
                                rowRenderer={this.props.rowRenderer}
                                getValidFilterValues={(columnKey) =>
                                    getValidFilterValues
                                        ? getValidFilterValues(columnKey)
                                        : () => {
                                              return [];
                                          }
                                }
                            /> */}
                            {!readOnly ? (
                                settings.Orientation === MAINTENANCE_PAGE_CONSTANTS.ORIENTATION_HORIZONTAL ? (
                                    <DrcDialog
                                        className={classes.dialog}
                                        maxWidth={'100%'}
                                        open={this.state.dialogEditOpen}
                                        buttons={
                                            <React.Fragment>
                                                <DrcButton isSecondary onClick={this.onAddEditNo} floatRight>
                                                    Cancel
                                                </DrcButton>
                                                <DrcButton isPrimary disabled={!this.state.isEnabled} onClick={this.onAddEditYes} floatRight>
                                                    Save
                                                </DrcButton>
                                            </React.Fragment>
                                        }
                                        title={this.state.addEditText}
                                    >
                                        <hr />
                                        {this.state.readOnlyArray.length === 0 ? null : (
                                            <DrcPanel maxWidth="100%" style={{ padding: 10 }}>
                                                <div className="row">{this.state.readOnlyArray}</div>
                                            </DrcPanel>
                                        )}
                                        <div className="row" style={{ alignItems: 'center' }}>
                                            {this.state.editArray}
                                        </div>
                                    </DrcDialog>
                                ) : (
                                    <div className="col-xs-12 col-sm-4">
                                        <legend className={classes.legendTitle}>{this.state.addEditText}</legend>
                                        <hr />
                                        {this.state.readOnlyArray.length === 0 ? null : (
                                            <DrcPanel maxWidth="100%" style={{ padding: 10 }}>
                                                <div className="row">{this.state.readOnlyArray}</div>
                                            </DrcPanel>
                                        )}
                                        {this.state.editArray}
                                        <DrcButton isSecondary onClick={this.onAddEditNo} floatRight>
                                            {textOptions.CancelBtn}
                                        </DrcButton>
                                        <DrcButton isPrimary disabled={!this.state.isEnabled} onClick={this.onAddEditYes} floatRight>
                                            {textOptions.SaveBtn}
                                        </DrcButton>
                                    </div>
                                )
                            ) : null}
                        </div>
                    </DrcPanel>
                </DrcMain>
                <DrcDialog
                    className={classes.dialog}
                    open={this.state.dialogAddNewOpen}
                    title={this.state.addEditText}
                    buttons={
                        <React.Fragment>
                            <DrcButton isSecondary onClick={this.onAddNewNo} floatRight>
                                {textOptions.CancelBtn}
                            </DrcButton>
                            <DrcButton isPrimary disabled={!this.state.isEnabled} onClick={this.onAddNewYes} floatRight>
                                {textOptions.SaveBtn}
                            </DrcButton>
                        </React.Fragment>
                    }
                >
                    <hr />
                    {this.state.readOnlyArray.length === 0 ? null : (
                        <DrcPanel maxWidth="100%" style={{ padding: 10 }}>
                            <div className="row">{this.state.readOnlyArray}</div>
                        </DrcPanel>
                    )}
                    <div className="row">{this.state.editArray}</div>
                </DrcDialog>
                <DrcDialog
                    className={classes.dialog}
                    title={this.state.dialogText}
                    open={this.state.dialogOpen}
                    buttons={
                        <React.Fragment>
                            <DrcButton isPrimary onClick={this.onDialogYes}>
                                {textOptions.ConfirmAcceptBtn}
                            </DrcButton>
                            <DrcButton isSecondary onClick={this.onDialogNo}>
                                {textOptions.ConfirmRejectBtn}
                            </DrcButton>
                        </React.Fragment>
                    }
                />
                {children}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DrcPageMaintenance);
