import { DriscollsDataTable, DriscollsVContainer } from '@driscollsinc/style-guide-web2.0';
import styles from './OverrideTable.module.scss';

const OverrideTable = ({ rows, gridColumns }) => {
    return (
        <DriscollsVContainer noPadding className={styles.container}>
            <DriscollsDataTable
                isLoading={false}
                columns={gridColumns}
                data={rows}
                textFilterPlaceholder={true}
                showHover={true}
                hidePagination={true}
            />
        </DriscollsVContainer>
    );
};

export default OverrideTable;
