import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { Middleware } from 'one-ring';
import { DuDateUtilities } from 'driscolls-react-utilities';
import GoodIcon from '@material-ui/icons/CheckCircle';
import UnknownIcon from '@material-ui/icons/Help';
import BadIcon from '@material-ui/icons/Error';

const HEALTH_UNKNOWN = 0;
const HEALTH_GOOD = 1;
const HEALTH_BAD = 2;

class DrcHealthCheck extends Component {
    constructor(props) {
        super(props);

        this.state = {
            apiName: this.props.apiName,
            apiUrl: this.props.apiUrl,
            apiMethod: this.props.apiMethod,
            confirmationFunction:
                this.props.confirmationFunction ||
                (() => {
                    return true;
                }),
            health: HEALTH_UNKNOWN,
            duration: ' - ',
            execution: ' - '
        };
    }

    async componentDidMount() {
        this.loadApi();
    }

    loadApi = async () => {
        if (this.state.health !== HEALTH_UNKNOWN) {
            return;
        }

        const token = await this.props.authService.getAccessToken();
        const start = new Date();

        Middleware.Send(
            '',
            token,
            this.state.apiUrl,
            this.state.apiMethod,
            {},
            {
                overrideRequestMapping: true,
                overrideResponseMapping: true
            }
        )
            .then((data) => {
                const end = new Date();
                const duration = DuDateUtilities.TimeSpanToString(start, end);

                if (data && data.response) {
                    this.setState({ health: HEALTH_BAD, duration, execution: DuDateUtilities.FormatDateTimeFromIso(start.toISOString(), true) });
                    (this.props.done || (() => {}))(this.state.apiName, HEALTH_BAD);
                    return;
                }

                var result = this.state.confirmationFunction(data) ? HEALTH_GOOD : HEALTH_BAD;

                this.setState({ health: result, duration, execution: DuDateUtilities.FormatDateTimeFromIso(start.toISOString(), true) });
                (this.props.done || (() => {}))(this.state.apiName, result);

                return;
            })
            .catch((e) => {
                const end = new Date();
                const duration = DuDateUtilities.TimeSpanToString(start, end);

                this.setState({ health: HEALTH_BAD, duration, execution: DuDateUtilities.FormatDateTimeFromIso(start.toISOString(), true) });
                (this.props.done || (() => {}))(this.state.apiName, HEALTH_BAD);
            });
    };

    render() {
        const { apiName, health, duration, execution } = this.state;

        return (
            <div className={`row ${this.props.className} health_${health}`} key={apiName}>
                <div className="col-sm-8 col-md-5 name">{apiName}</div>
                <div className="col-sm-4 col-md-2 health">
                    {health === HEALTH_UNKNOWN ? (
                        <div>
                            <UnknownIcon />
                            <span>Unknown</span>
                        </div>
                    ) : health === HEALTH_GOOD ? (
                        <div>
                            <GoodIcon />
                            <span>Good</span>
                        </div>
                    ) : (
                        <div>
                            <BadIcon />
                            <span>Bad</span>
                        </div>
                    )}
                </div>
                <div className="col-sm-8 col-md-3 execution">{execution}</div>
                <div className="col-sm-4 col-md-2 duration">{duration}</div>
            </div>
        );
    }
}

export default withOktaAuth(DrcHealthCheck);
