import React, { useState } from 'react';
import {
    ApplicationPageTitle,
    ApplicationPage,
    DriscollsVContainer,
    DriscollsHContainer,
    DriscollsTextField,
    DriscollsSelect,
    DriscollsButton,
    DriscollsTranslatedValue,
    ButtonVariant,
    useForm,
    yupResolver
} from '@driscollsinc/style-guide-web2.0';
import { useNavigate } from 'react-router-dom';
import styles from './Sites.module.scss';
import { useParams } from 'react-router-dom';
import { NAMESPACE } from '../../../i18n';
import { SiteList } from '../Site/mockdata';
import { SiteSchema } from '../../schemas/createSite';

const EditSiteInfo = () => {
    const [data, setData] = useState(SiteList.Assignments);
    const [editedWarehouses, setEditedWarehouses] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();

    const options = data[id].Warehouses.map((warehouse) => ({
        value: warehouse.Name,
        label: warehouse.Name
    }));

    const handleWarehousesChange = (selectedOptions: any[]) => {
        setEditedWarehouses(selectedOptions);
    };

    const handleCancel = () => {
        navigate('/demea/sites');
    };

    const {
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(SiteSchema)
    });

    const onSubmit = (data: unknown) => {
        console.log('on submit>>', data);
    };

    return (
        <ApplicationPage className={styles.qmpApplicationPage}>
            <ApplicationPageTitle
                title={{ text: 'Sites', nameSpace: NAMESPACE.SITES }}
                description={{ text: 'Assign Warehouses to a site and site to a Region', nameSpace: NAMESPACE.SITES }}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <DriscollsVContainer>
                    <DriscollsHContainer className={styles.editForm} noPadding>
                        <DriscollsTextField
                            className={styles.name}
                            label={'Region'}
                            name="region"
                            nameSpacing={NAMESPACE.USER_MANAGEMENT}
                            value={data[id].Region.Name}
                            readOnlyField={true}
                        />
                        <DriscollsTextField
                            className={styles.name}
                            label={'Site'}
                            name="site"
                            nameSpacing={NAMESPACE.USER_MANAGEMENT}
                            value={data[id].Site.Name}
                            readOnlyField={true}
                        />
                    </DriscollsHContainer>
                </DriscollsVContainer>
                <DriscollsVContainer>
                    <DriscollsSelect
                        className={styles.multiSelectField}
                        containerClassName={styles.multiSelectContainer}
                        isMulti={true}
                        onChange={handleWarehousesChange}
                        virtualization={true}
                        defaultValue={options[0]}
                        placeholder={<DriscollsTranslatedValue value="Warehouses" nameSpacing={NAMESPACE.SITES} />}
                        options={options}
                        error={editedWarehouses.length === 0 && errors.selectedWarehouses?.message}
                    />
                </DriscollsVContainer>
                <DriscollsVContainer>
                    <DriscollsHContainer className={styles.controls}>
                        <DriscollsButton
                            variant={ButtonVariant.SECONDARY}
                            label={<DriscollsTranslatedValue nameSpacing={NAMESPACE.COMMON} value="Cancel" />}
                            onClick={handleCancel}
                        />
                        <DriscollsButton
                            label={<DriscollsTranslatedValue nameSpacing={NAMESPACE.COMMON} value="Save" />}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </DriscollsHContainer>
                </DriscollsVContainer>
            </form>
        </ApplicationPage>
    );
};

export default EditSiteInfo;
