import { useState } from 'react';
import style from './AddUsers.module.scss';
import {
    DriscollsButton,
    FaIcons,
    ButtonSize,
    ButtonVariant,
    EmailSelect,
    DriscollsVContainer,
    DriscollsTranslatedValue,
    DriscollsToast,
    DriscollsToastSeverity
} from '@driscollsinc/style-guide-web2.0';
import { NAMESPACE } from '../../../../../../i18n';
import UsersList from '../userslist/UsersList';
import { TLoadOptions } from '../../../../../types/users';

interface IAddUsers {
    warehouseId?: string;
    loadOptions: TLoadOptions;
}

const AddUsers = ({ loadOptions, warehouseId }: IAddUsers) => {
    const [show, setShow] = useState(false);
    const [users, setUsers] = useState([] as string[]);
    const [toast, setToast] = useState<{ open: boolean; message: string }>({ open: false, message: '' });

    const reset = () => {
        setShow(false);
        setUsers([]);
    };

    return (
        <div className={`${style.popoverWrapper} ${show ? style.show : ''}`}>
            <section className={`${style.popoverTitle} ${show ? style.show : ''}`}>
                {warehouseId && (
                    <DriscollsButton variant={ButtonVariant.TEXT} onClick={() => setShow(!show)} buttonSize={ButtonSize.MEDIUM}>
                        <FaIcons.FaCirclePlus />
                    </DriscollsButton>
                )}
            </section>
            <section className={style.popoverContent}>
                <DriscollsVContainer>
                    <EmailSelect
                        label={<DriscollsTranslatedValue value="Search Email" nameSpacing={NAMESPACE.USER_MANAGEMENT} />}
                        options={loadOptions}
                        onChange={(val) => {
                            users.includes(val) ? setToast({ open: true, message: 'This user is already added!' }) : setUsers([...users, val]);
                        }}
                    />
                    <UsersList users={users} setUsers={setUsers} isPopup />
                </DriscollsVContainer>
                <div className={style.popupFooter}>
                    <DriscollsButton
                        label={<DriscollsTranslatedValue value="Cancel" nameSpacing={NAMESPACE.USER_MANAGEMENT} />}
                        onClick={reset}
                        className={style.btn}
                        variant={ButtonVariant.SECONDARY}
                        buttonSize={ButtonSize.SMALL}
                    />
                    <DriscollsButton
                        onClick={() => {
                            console.log('users with warehouse id', warehouseId, users);
                            reset();
                        }}
                        className={style.btn}
                        label={<DriscollsTranslatedValue value="Save" nameSpacing={NAMESPACE.COMMON} />}
                        variant={ButtonVariant.PRIMARY}
                        buttonSize={ButtonSize.SMALL}
                    />
                </div>
            </section>

            <DriscollsToast
                open={toast.open}
                nameSpacing={NAMESPACE.USER_MANAGEMENT}
                message={toast.message}
                severity={DriscollsToastSeverity.INFO}
                onClose={() => setToast({ open: false, message: '' })}
            />
        </div>
    );
};

export default AddUsers;
