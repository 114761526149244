import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DrcKeyValueTable from './DrcKeyValueTable';

const styles = (theme) => ({
    button: {
        margin: theme.spacing(1)
    }
});

class DrcGlossaryList extends React.Component {
    render() {
        const { classes } = this.props;
        return <DrcKeyValueTable className={classes.button}>{this.props.children}</DrcKeyValueTable>;
    }
}

export default withStyles(styles)(DrcGlossaryList);
