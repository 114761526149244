import {
    DriscollsHContainer,
    DriscollsTypographyTranslated,
    DriscollsTypographyEnum,
    DriscollsTypographyStyles
} from '@driscollsinc/style-guide-web2.0';
import style from './AssignmentCard.module.scss';
import { useState } from 'react';
import { NAMESPACE } from '../../../../i18n';
import Warehouse from './warehouse/Warehouse';
import { TAssignments, TLoadOptions, TWarehouse } from '../../../types/users';

interface IAssignmentCard {
    rowData: TAssignments;
    loadOptions: TLoadOptions;
}

const AssignmentCard = ({ rowData, loadOptions }: IAssignmentCard) => {
    const { Warehouses: warehouses } = rowData;
    const [errorMessage, setTheErrorMessage] = useState<Array<string> | null>(null);

    return (
        <DriscollsHContainer className={style.assignmentsMain} wrap>
            {errorMessage !== null && (
                <div className={style.errorContainer}>
                    <DriscollsTypographyTranslated
                        className={style.error}
                        type={DriscollsTypographyEnum.P}
                        nameSpacing={NAMESPACE.USER_MANAGEMENT}
                        typographyStyle={DriscollsTypographyStyles.SUBTITLE5}
                    >{`${errorMessage}`}</DriscollsTypographyTranslated>
                </div>
            )}
            <DriscollsHContainer className={style.assignmentsContainer} wrap>
                {warehouses.length > 0 &&
                    warehouses.map((warehouse: TWarehouse) => {
                        return <Warehouse key={warehouse.Id} warehouse={warehouse} loadOptions={loadOptions} />;
                    })}
            </DriscollsHContainer>
        </DriscollsHContainer>
    );
};

export default AssignmentCard;
