import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { withOktaAuth } from '@okta/okta-react';
import { DrcPageDataMaintenance, DrcPanel, DrcSelect, DrcTooltip } from 'driscolls-react-components';
import { DuDateUtilities } from 'driscolls-react-utilities';
import { Middleware } from 'one-ring';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setManagerMaintenanceData } from '../../actions/ManagerMaintenance';
import { MAINTENANCE_DELETE, PRODUCING_AREA, PRODUCING_AREA_PLACEHOLDER } from '../../language/english';
import APIEndPoints from '../../services/api';
import { getManagerMaintenancePayload } from '../../util/utils';

const PAGE_TITLE = 'QA Manager';
const PAGE_TYPE = 'managerMaintenance';

let styles = (theme) => ({
    main: {
        margin: 0,
        paddingBottom: '0px !important'
    },
    grid: {
        '& .selected > div': {
            backgroundColor: '#cfc3deba !important'
        },
        '& .nonSelected > div': {
            backgroundColor: '#d9d0e4 !important'
        },
        '& .react-grid-Toolbar .btn:after': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            height: '27px',
            color: 'white'
        },
        '& .react-grid-Header': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important'
        }
    },
    success: {
        color: theme.palette.primary.main
    },
    gridContainer: {
        paddingLeft: '24px'
    },
    layout: {
        width: '97%'
    },
    dialog: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px',
            width: '100%'
        },
        '& .p-datatable-thead': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            color: '#fff'
        },
        '& .p-datatable-row': {
            fontWeight: 600
        },
        '& .p-datatable-scrollable-wrapper': {
            maxHeight: 600
        }
    }
});

const emailformatter = (value) => {
    return (
        <DrcTooltip tipText={value ? value : ''}>
            <span>
                <a style={{ color: '#6f5091', textDecoration: 'none' }} href={'mailto:' + value} title={value}>
                    {value}
                </a>
            </span>
        </DrcTooltip>
    );
};

const producingAreaFormatter = (value) => {
    return (
        <DrcTooltip tipText={value ? value : ''}>
            <span>{value ? value : ''}</span>
        </DrcTooltip>
    );
};

const dateTimeFormatter = (value) => {
    return (
        <DrcTooltip tipText={value ? DuDateUtilities.ToPrettyDateTime(value) : ''}>
            <span>{value ? DuDateUtilities.ToPrettyDateTime(value) : ''}</span>
        </DrcTooltip>
    );
};

const Maintenance = (props) => {
    const [producingAreaFilter, setProducingAreaFilter] = useState('');
    const [previousProducingArea, setPreviousProducingArea] = useState('');
    const [messageDialog, setMessageDialog] = useState({
        showMessage: false,
        message: '',
        severity: 'success'
    });

    const columns = [
        {
            key: 'ProducingAreaCode',
            name: 'Producing Area',
            inputType: 'Select',
            defaultValue: '',
            selectOptions: props.producingArea,
            isRequired: true,
            filter: true,
            columnTemplate: (row) => producingAreaFormatter(row.ProducingAreaCode)
        },
        {
            key: 'LocalQAManagerEmailAddress',
            name: 'Email',
            isRequired: true,
            validationType: 'email',
            filter: true,
            columnTemplate: (row) => emailformatter(row.LocalQAManagerEmailAddress)
        },
        {
            key: 'CreatedBy',
            name: 'Created By',
            isDisabled: true,
            isHidden: true,
            filter: true,
            columnTemplate: (row) => emailformatter(row.CreatedBy)
        },
        {
            key: 'CreatedDateTime',
            name: 'Created Date Time',
            isDisabled: true,
            isHidden: true,
            filter: true,
            filterElement: 'datePicker',
            columnTemplate: (row) => dateTimeFormatter(row.CreatedDateTime)
        }
    ];

    useEffect(() => {
        getData('');
    }, []);
    const getData = (producingArea) => {
        let producingAreaCode = producingArea;
        if (producingArea === '') {
            producingAreaCode = '';
        }
        props.authService.getAccessToken().then((token) => {
            Middleware.Send(
                PAGE_TYPE,
                token,
                APIEndPoints.GET_ASSIGNED_PRODUCING_AREAS(producingAreaCode, props.loggedInUser),
                'GET',
                { Type: PAGE_TYPE },
                { loadingMessage: 'Fetching assigned producing area...' }
            )
                .then((data) => {
                    if (data && data.length) {
                        props.setManagerMaintenanceData(data);
                    } else {
                        props.setManagerMaintenanceData([]);
                    }
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened, but I am not sure if it is good or bad!';
                    props.setManagerMaintenanceData([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };

    const onAdd = (addObj) => {
        let payload = getManagerMaintenancePayload(addObj, props.producingArea, props.loggedInUser);
        props.authService.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.POST_PRODUCING_AREA, 'POST', payload, {
                overrideRequestMapping: true,
                loadingMessage: 'Assigning producing area...'
            })
                .then((_res) => {
                    if (_res == 'User does not exist in okta') {
                        setMessageDialog({
                            showMessage: true,
                            message: 'User does not exist in OKTA. Please try with valid Email ID!',
                            severity: 'error'
                        });
                        return;
                    }
                    let selectedProducingArea = props.producingArea.filter((item) => item.value === payload.ProducingAreaCode);
                    setProducingAreaFilter(selectedProducingArea);
                    setPreviousProducingArea(selectedProducingArea);
                    getData(payload.ProducingAreaCode);
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };
    const onDelete = (deleteVal) => {
        let payload = getManagerMaintenancePayload(deleteVal, props.producingArea, props.loggedInUser);
        payload.IsDeleted = true;
        payload.LocalManagerId = deleteVal.LocalManagerId;
        props.authService.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.POST_PRODUCING_AREA, 'POST', payload, {
                overrideRequestMapping: true,
                loadingMessage: 'Deleting assigned producing area...'
            })
                .then((_res) => {
                    let updatedData = props.managerMaintenanceGrid.filter((item) => item.LocalManagerId !== deleteVal.LocalManagerId);
                    props.setManagerMaintenanceData(updatedData);
                    setMessageDialog({
                        showMessage: true,
                        message: MAINTENANCE_DELETE,
                        severity: 'success'
                    });
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        type: '',
                        severity: 'error'
                    });
                });
        });
    };

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageDialog({ showMessage: false });
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleOnChangeOfProducingArea = (option) => {
        if (option) {
            if (previousProducingArea.value !== option.value) {
                setPreviousProducingArea(option);
                setProducingAreaFilter(option);
                getData(option.value);
            }
        } else {
            setPreviousProducingArea('');
            setProducingAreaFilter('');
            getData('');
        }
    };

    const gridDataFormatter = (data) => {
        const modifiedData = [];
        if (data.length) {
            let { ProducingAreaCode, LocalQAManagerEmailAddress } = data[0];
            modifiedData.push({
                ProducingAreaCode: ProducingAreaCode,
                LocalQAManagerEmailAddress: LocalQAManagerEmailAddress,
                CreatedBy: props.loggedInUser,
                CreatedDateTime: new Date().toISOString()
            });
            return modifiedData;
        } else {
            return [];
        }
    };

    const { classes } = props;

    return (
        <DrcPanel
            style={{ maxWidth: '100%', border: 'none', boxShadow: '0 2px 4px 0 #C3C3C3', marginBottom: '0px' }}
            className={props.classes.layout}
        >
            <Grid container className={classes.gridContainer}>
                <Grid item xs={8} sm={5} md={4} lg={3}>
                    <DrcSelect
                        isClearable={true}
                        label={PRODUCING_AREA}
                        options={props.producingArea}
                        value={producingAreaFilter}
                        placeholder={PRODUCING_AREA_PLACEHOLDER}
                        onChange={handleOnChangeOfProducingArea}
                    />
                </Grid>
            </Grid>

            <DrcPageDataMaintenance
                className={classes.dialog}
                columns={columns}
                classes={classes}
                data={props.managerMaintenanceGrid}
                fullWidth={false}
                onAdd={onAdd}
                onDelete={onDelete}
                settings={{
                    EnableExport: false,
                    EnableAdd: props.userGroup === 'Admin' ? true : false,
                    EnableDelete: props.userGroup === 'Admin' ? true : false,
                    EnableEdit: false
                }}
                textOptions={{ AddBtn: 'Add New' }}
                type={PAGE_TITLE}
                gridDataFormatter={gridDataFormatter}
                resultCount={props.managerMaintenanceGrid.length}
            />
            <Snackbar open={messageDialog.showMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={messageDialog.severity}>
                    {messageDialog.message}
                </Alert>
            </Snackbar>
        </DrcPanel>
    );
};

function mapStateToProps(state) {
    return {
        pageTitle: state.rootReducer.pageTitle,
        producingArea: state.overrideReducer.producingArea,
        userGroup: state.masterReducer.userGroup,
        loggedInUser: state.masterReducer.loggedInUser,
        managerMaintenanceGrid: state.managerMaintenanceReducer.managerMaintenanceGrid
    };
}

export default connect(mapStateToProps, { setManagerMaintenanceData })(withOktaAuth(withStyles(styles)(Maintenance)));
