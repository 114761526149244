import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DuDateUtilities, DuThemeUtilities } from 'driscolls-react-utilities';

Number.prototype.pad = function(size) {
    var s = String(this);
    while (s.length < (size || 2)) {
        s = '0' + s;
    }
    return s;
};

var styles = (theme) => ({
    root: {
        marginTop: '6px',
        marginBottom: '5px',
        '& .MuiButtonBase-root.MuiIconButton-root': {
            marginRight: '-15px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.light.buttonBorder + theme.important
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.light.accent.primary + theme.important
        },
        '& .MuiInputAdornment-root button': {
            color: theme.light.text.primary + theme.important
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.light.text.primary + theme.important
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.light.accent.primary + theme.important
        },
        [theme.darkTheme]: {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.dark.buttonBorder + theme.important
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.dark.buttonBorder + theme.important
            },
            '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.dark.text.primary + theme.important
                }
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.dark.accent.primary + theme.important
            },
            '& input.MuiInputBase-input': {
                color: theme.dark.text.primary + theme.important
            },
            '& div.MuiInputBase-root': {
                backgroundColor: theme.dark.secondary + theme.important
            },
            '& .MuiInputAdornment-root button': {
                color: theme.dark.text.primary + theme.important
            }
        }
    },
    dialog: {
        '& .MuiToolbar-root': {
            display: 'block',
            height: '45px',
            minHeight: '45px'
        },
        '& .MuiPickersToolbarButton-toolbarBtn:nth-of-type(1)': {
            marginLeft: theme.spacing(2)
        },
        '& .MuiPickersToolbarButton-toolbarBtn:nth-of-type(2)': {
            float: 'left'
        },
        '& .MuiPickersToolbarText-toolbarTxt': {
            fontSize: '2rem',
            lineHeight: '1.75'
        },
        '& .MuiTypography-body2, & .MuiTypography-caption': {
            fontSize: '1rem'
        }
    },
    labelError: {
        color: theme.light.text.errorTitle,
        fontSize: '1.25rem',
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    },
    invalidDate: {
        '& .MuiInputBase-root': {
            borderColor: DuThemeUtilities.DefaultColors.primary.red + theme.important
        }
    }
});

const todaysDate = new Date();
const todaysFormattedDate = DuDateUtilities.ToString(todaysDate);

class DrcDatePicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = { dateIsValid: true };
    }

    handleOnChange = (date, value) => {
        //Determine if this is a valid date
        let dateIsValid = (value || '').length <= 0 || (date !== null && date instanceof Date && !isNaN(date));

        //If not valid and state is not set change state
        if (!dateIsValid && this.state.dateIsValid) {
            this.setState({ dateIsValid });

            this.props.onChange(date, value);
            return;
        }

        //If valid and state is set change state
        if (dateIsValid && !this.state.dateIsValid) {
            this.setState({ dateIsValid });

            this.props.onChange(date, value);
            return;
        }

        this.props.onChange(date, value);
    };

    render() {
        const {
            label,
            placeholder,
            format,
            variant,
            helperText,
            width,
            style,
            InputProps,
            InputLabelProps,
            showTodayButton,
            classes,
            selectedDate,
            onAccept,
            onClose,
            onChange,
            onOpen,
            required,
            refuseRegex,
            ...other
        } = this.props;

        const { dateIsValid } = this.state;

        return (
            <KeyboardDatePicker
                className={`${classes.root} ${!dateIsValid ? classes.invalidDate : null}`}
                style={{ width: width || '100%', ...style }}
                fullWidth={true}
                margin="dense"
                inputVariant={variant || 'outlined'}
                label={
                    required ? (
                        <span>
                            {label || ''} <span className={classes.labelError}>*</span>
                        </span>
                    ) : (
                        label || ''
                    )
                }
                placeholder={placeholder || todaysFormattedDate}
                value={selectedDate}
                onChange={this.handleOnChange}
                onAccept={onAccept}
                onClose={onClose}
                onOpen={onOpen}
                format={format || 'MMM-dd-yyyy'}
                clearable={true}
                showTodayButton={showTodayButton}
                InputProps={{
                    ...InputProps
                }}
                InputLabelProps={{
                    ...InputLabelProps
                }}
                DialogProps={{ className: classes.dialog }}
                helperText={helperText || ''}
                error={(helperText || '').length > 0}
                refuse={refuseRegex || /[^janfebmryuprlgsoctvd0-9]+/gi}
                {...other}
            />
        );
    }
}

export default withStyles(styles)(DrcDatePicker);
