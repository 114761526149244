import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { DrcMain, DrcOktaWidget, DrcSignInVersionFooter, DrcImage } from 'driscolls-react-components';
import { setPageTitleAction } from '../actions/actions';

const Home = (props) => {
    const [state, setState] = useState({
        authenticated: null,
        error: null
    });
    const location = useLocation();

    useEffect(() => {
        if (!location.pathname.includes('demea')) {
            import('../../index.scss');
        }
    }, [location]);

    const checkAuthentication = async () => {
        const authenticated = props.authState.isAuthenticated;
        if (authenticated !== state.authenticated) {
            setState({ ...state, authenticated });
        }
    };

    useEffect(() => {
        props.setPageTitle('');
        checkAuthentication();
    }, [checkAuthentication]);

    const onSuccess = (res) => {
        if (res.status === 'SUCCESS') {
            return props.authService.redirect({
                sessionToken: res.session.token
            });
        } else {
            // The user can be in another authentication state that requires further action.
            // For more information about these states, see:
            //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
        }
    };

    const onError = (err) => {
        console.log('error logging in', err);
    };

    const errorMessage = state.error ? <span className="error-message">{state.error}</span> : null;

    return state.authenticated ? (
        window.config.uiConfig.v2UI === 'true' ? (
            <Navigate to="/demea" />
        ) : (
            <Navigate to="/InitializeApplication/" />
        )
    ) : (
        <React.Fragment>
            <DrcMain transparent>
                <DrcImage
                    src={require('../images/Wood_Rasp_Full_Hd.png')}
                    webp={require('../images/Wood_Rasp_Full_Hd.webp')}
                    style={{
                        position: 'absolute',
                        height: '100vh',
                        width: '100vw',
                        top: 0,
                        left: 0,
                        objectFit: 'cover',
                        zIndex: -1
                    }}
                    alt=""
                />
                <DrcOktaWidget baseUrl={window.config.OKTA_URL} config={{ logo: '/Login_Logo.png' }} onSuccess={onSuccess} onError={onError} />
                <DrcSignInVersionFooter errorMessage={errorMessage} />
            </DrcMain>
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title))
});

export default connect(null, mapDispatchToProps)(withOktaAuth(Home));
