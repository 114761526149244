import React from 'react';
import { Navigate } from 'react-router-dom';
import { Middleware } from 'one-ring';
import LoggingUtilities from './LoggingUtilities';
import APIEndPoints from '../services/api';

const timeSpanInfoAlert = 7200000; // 2 hours
const timeSpanWarningAlert = 32400000; // 9 hours
const DEFAULT_CACHE_DURATION_IN_MINUTES = 60;

let lastMasterDataLoadTime = null;
let hasLoggedMasterDataWarning = false;
let hasLoggedMasterDataInfo = false;
let redirectLocationReducerFunction = () => {
    /**/
};

const masterDataCalls = [
    {
        name: 'warehouses',
        endpoint: APIEndPoints.GET_WAREHOUSES,
        weight: 5,
        returnFunction: (data, props) => {
            props.setWarehouses(data.Results);
        }
    },
    {
        name: 'producingArea',
        endpoint: APIEndPoints.GET_PRODUCING_AREA,
        weight: 5,
        returnFunction: (data, props) => {
            props.setProducingAreaList(data.Results);
        }
    }
];

const getMasterData = (props, token, statusUpdate, onSuccess, onError) => {
    let apiCalls = [];

    masterDataCalls.forEach((call) => {
        let middlewareCall = Middleware.CreateSendCall(call.name, token, call.endpoint);

        middlewareCall.cacheLifespanInMinutes = DEFAULT_CACHE_DURATION_IN_MINUTES;

        apiCalls.push(
            Middleware.AttachReturnFunction(middlewareCall, call.weight, (data) => {
                call.returnFunction(data, props);
            })
        );
    });

    Middleware.SendMultiple('Initialize', apiCalls, statusUpdate)
        .then((message) => {
            lastMasterDataLoadTime = Date.now();
            onSuccess(message);
            hasLoggedMasterDataInfo = false;
            hasLoggedMasterDataWarning = false;
        })
        .catch((error) => {
            lastMasterDataLoadTime = Date.now();
            onError(error);
        });
};

const checkMasterData = (isMasterDataLoaded) => {
    if (!isMasterDataLoaded) {
        return false;
    }

    if (lastMasterDataLoadTime + timeSpanWarningAlert <= Date.now() && !hasLoggedMasterDataInfo) {
        hasLoggedMasterDataInfo = true;
        LoggingUtilities.Add(
            LoggingUtilities.CreateInfo(
                'MasterDataInit',
                'Master Data',
                "Master Data is outdated and hasn't been updated in a very long time. This can cause issues with missing items that may have been added throughout the day.",
                2,
                '/InitializeApplication/'
            )
        );
    }

    if (lastMasterDataLoadTime + timeSpanInfoAlert <= Date.now() && !hasLoggedMasterDataWarning) {
        hasLoggedMasterDataWarning = true;
        LoggingUtilities.Add(
            LoggingUtilities.CreateInfo(
                'MasterDataInit',
                'Master Data',
                "Master Data is a little stale and hasn't been updated in a while. This can cause issues with missing items that may have been added recently. It's a good idea to keep this up to date periodically.",
                3,
                '/InitializeApplication/'
            )
        );
    }

    return true;
};

const redirectToLoadMasterData = () => {
    redirectLocationReducerFunction(window.location.pathname);
    return <Navigate to="/InitializeApplication/" />;
};

let registerMasterDataReducerVariables = (setMasterDataRedirectLocation, _setMasterDataInitialized) => {
    redirectLocationReducerFunction = setMasterDataRedirectLocation;
};

const MasterDataUtilities = {
    Load: getMasterData,
    Check: checkMasterData,
    Redirect: redirectToLoadMasterData,
    Register: registerMasterDataReducerVariables
};

export default MasterDataUtilities;
