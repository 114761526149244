import SitesEntities from './SitesEntities';
import { DriscollsVContainer, DriscollsHContainer } from '@driscollsinc/style-guide-web2.0';
import { TSiteWarehouse } from '../../types/siteMapping';

interface SitesSubRowProps {
    warehouses?: Array<TSiteWarehouse>;
}

const SitesSubRow = (props: SitesSubRowProps) => {
    const { warehouses } = props;
    if (!warehouses) return null;
    return (
        <DriscollsVContainer>
            <DriscollsHContainer wrap>
                {warehouses.map((warehouse) => {
                    return <SitesEntities entities={warehouse} />;
                })}
            </DriscollsHContainer>
        </DriscollsVContainer>
    );
};

export default SitesSubRow;
