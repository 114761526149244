import style from './UsersList.module.scss';
import { FaIcons, IconSize, DriscollsTrashButton } from '@driscollsinc/style-guide-web2.0';

interface IUsersList {
    users: Array<string>;
    setUsers?: (value: string[]) => void;
    isPopup?: boolean;
}

const UsersList = ({ users, setUsers, isPopup }: IUsersList) => {
    return (
        <>
            {users.length > 0 &&
                users.map((user) => {
                    return (
                        <article className={style.userRow} id={user}>
                            <section className={style.nameSection}>
                                <span>
                                    <FaIcons.FaMessage className={style.icon} size={IconSize.SMALLER} />
                                </span>
                                <span className={style.name}>{user}</span>
                            </section>
                            <section className={style.actions}>
                                <DriscollsTrashButton
                                    isModalRequired={false}
                                    onClick={() => (isPopup ? setUsers(users.filter((u) => u !== user)) : null)}
                                />
                            </section>
                        </article>
                    );
                })}
        </>
    );
};

export default UsersList;
