import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { withOktaAuth } from '@okta/okta-react';
import { DrcPageDataMaintenance, DrcPanel, DrcSelect, DrcButton, DrcDialog } from 'driscolls-react-components';
import { Middleware } from 'one-ring';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setSummaryStatsData, setUserProducingArea } from '../../actions/SummaryStatsAction';
import { PRODUCING_AREA_PLACEHOLDER } from '../../language/english';
import APIEndPoints from '../../services/api';
import { berryTypes } from '../../Constant/BerryType';
import { DuDateUtilities } from 'driscolls-react-utilities';

const PAGE_TITLE = 'Summary Statistics';
const PAGE_TYPE = 'SummaryStatistics';
let styles = (theme) => ({
    main: {
        margin: 0,
        paddingBottom: '0px !important'
    },
    grid: {
        '& .selected > div': {
            backgroundColor: '#cfc3deba !important'
        },
        '& .nonSelected > div': {
            backgroundColor: '#d9d0e4 !important'
        },
        '& .react-grid-Toolbar .btn:after': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            height: '27px',
            color: 'white'
        },
        '& .react-grid-Header': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important'
        }
    },
    success: {
        color: theme.palette.primary.main
    },
    gridContainer: {
        paddingLeft: '24px'
    },
    layout: {
        width: '97%'
    },
    dialog: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px',
            width: '100%'
        },
        '& .p-datatable-thead': {
            background: 'none !important',
            backgroundColor: '#6f5091 !important',
            color: '#fff'
        },
        '& .p-datatable-row': {
            fontWeight: 600
        },
        '& .p-datatable-scrollable-wrapper': {
            maxHeight: 600
        }
    }
});

const SummaryStatistics = (props) => {
    const [producingAreaFilter, setProducingAreaFilter] = useState('');
    const [AddMode, setAddMode] = useState(false);
    const [producingAreaByUser, setProducingAreaByUser] = useState([]);
    const [messageDialog, setMessageDialog] = useState({
        showMessage: false,
        message: '',
        severity: 'success'
    });
    const [commodity, setCommodity] = useState(null);
    const [formattedData, setFormattedData] = useState([]);
    const [producingAreaAddValue, setProducingAreaAddValue] = useState();
    const [commodityAdd, setCommodityAdd] = useState(null);
    const [oldShortName, setOldShortName] = useState('');
    const [oldShortNameDialog, setOldShortNameDialog] = useState('');
    const [newShortName, setNewShortName] = useState('');
    const [newShortNameOption, setNewShortNameOption] = useState([]);
    const [oldShortNameOption, setOldShortNameOption] = useState([]);
    const [newShortNameDialog, setNewShortNameDialog] = useState('');
    const dateTimeFormatter = ({ value, row }) => {
        getColumn(value, row);
        return <span>{value ? DuDateUtilities.ToPrettyDateTime(value) : ''}</span>;
    };
    const columns = [
        {
            key: 'ProducingAreaCode',
            name: 'Producing Area',
            editDisabled: true,
            addDisabled: false,
            isRequired: true,            
            selectOptions: producingAreaByUser,
            columnTemplate: (row) => row.ProducingAreaCode
        },
        {
            key: 'CommodityCode',
            name: 'Commodity',
            addDisabled: false,
            isRequired: true,
            editDisabled: false,
            inputType: 'Select',
            selectOptions: berryTypes,
            columnTemplate: (row) => row.CommodityCode
        },
        {
            key: 'OldShortName',
            name: 'Old Short Name',
            addDisabled: false,
            isRequired: true,
            editDisabled: false,
            inputType: 'Select',
            selectOptions: oldShortNameOption,
            columnTemplate: (row) => row.OldShortName
        },
        {
            key: 'ShortName',
            name: 'New Short Name',
            addDisabled: false,
            isRequired: true,
            editDisabled: false,
            inputType: 'Select',
            selectOptions: newShortNameOption,
            columnTemplate: (row) => row.ShortName
        },
        {
            key: 'ModifiedBy',
            name: 'Modified By',
            isHidden: true,
            isDisabled: true
        },
        {
            key: 'ModifiedDateTime',
            name: 'Modified Date Time',
            isDisabled: true,
            isHidden: true,
            columnTemplate: (row) => row.ModifiedDateTime,
            formatter: dateTimeFormatter
        }
    ];
    useEffect(() => {
        getProducingArea((data) => {
            if (data) {
                let procuingArea = data.map((item) => {
                    return { value: item.ProducingArea, label: item.ProducingAreaCode };
                });
                setProducingAreaByUser(
                    procuingArea.sort(function (a, b) {
                        return Number(a.value - b.value);
                    })
                );
            } else {
                setProducingAreaByUser([]);
            }
        });
        getData();
    }, []);

    useEffect(() => {
        getData(
            producingAreaFilter && producingAreaFilter.value,
            commodity && commodity.value,
            newShortName && newShortName.value,
            oldShortName && oldShortName.value
        );
    }, [producingAreaFilter, commodity, newShortName, oldShortName]);

    function formProducingAreaLabel(code){
        let resLabel=code;
        let codeString=code.trim().toString();
        props.producingArea.forEach((element,ind)=>{
            if(element.value === codeString){
                resLabel= element.label;
            }
        })
        return resLabel
    }

    useEffect(() => {
        const formatData = (items) => {
            let data = [];
            data = items.map((item) => {
                item.ProducingAreaCode = formProducingAreaLabel(item.ProducingAreaCode);
                return item;
            });
            setFormattedData(data);
        };
        formatData(props.summaryStatsList);
    }, [props.summaryStatsList]);

    const getProducingArea = (callback) => {
        props.authService.getAccessToken().then((token) => {
            Middleware.Send(
                'managerMaintenance',
                token,
                APIEndPoints.GET_USER_PRODUCING_AREAS(props.loggedInUser),
                'GET',
                { Type: 'managerMaintenance' },
                { loadingMessage: '' }
            )
                .then((data) => {
                    if (data) {
                        props.setUserProducingArea(data);
                        callback(data);
                    } else {
                        props.setUserProducingArea([]);
                        callback([]);
                    }
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened, but I am not sure if it is good or bad!';
                    callback([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };
    useEffect(() => {
        getMLookupDataOldShort();
        getMLookupDataShortNew();
    }, []);

    const getMLookupDataShortNew = () => {
        props.authService.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.GET_M_LOOKUP('SHORT NAME'), 'GET', { Type: PAGE_TYPE }, { loadingMessage: '' })
                .then((data) => {
                    let shortNameSort = data.map((item) => {
                        return { value: item.LookupCode, label: item.Description };
                    });
                    setNewShortNameOption(
                        shortNameSort.sort(function (a, b) {
                            return Number(a.value - b.value);
                        })
                    );
                })
                .catch((error) => {
                    let message = 'Something happened, but I am not sure if it is good or bad!';
                    setNewShortNameOption([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };
    const getMLookupDataOldShort = () => {
        props.authService.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.GET_M_LOOKUP('OLD SHORT NAME'), 'GET', { Type: PAGE_TYPE }, { loadingMessage: '' })
                .then((data) => {
                    let shortNameSort = data.map((item) => {
                        return { value: item.LookupCode, label: item.Description };
                    });
                    setOldShortNameOption(
                        shortNameSort.sort(function (a, b) {
                            return Number(a.value - b.value);
                        })
                    );
                })
                .catch((error) => {
                    let message = 'Something happened, but I am not sure if it is good or bad!';
                    setOldShortNameOption([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };
    const getData = (producingAreaCode) => {
        props.authService.getAccessToken().then((token) => {
            Middleware.Send(
                PAGE_TYPE,
                token,
                APIEndPoints.GET_SUMMARY_STATISTICS(
                    producingAreaCode,
                    commodity && commodity.value,
                    newShortName && newShortName.value,
                    oldShortName && oldShortName.value
                ),
                'GET',
                { Type: PAGE_TYPE },
                { loadingMessage: 'Fetching Summary Statistics...' }
            )
                .then((data) => {
                    if (data) {
                        props.setSummaryStatsData(data);
                    } else {
                        props.setSummaryStatsData([]);
                    }
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened, but I am not sure if it is good or bad!';

                    props.setSummaryStatsData([]);
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        severity: 'error'
                    });
                });
        });
    };
    const onEdit = (oldValue, newValue) => {
        let payload = {
            CreatedBy: oldValue.CreatedBy,
            ModifiedDateTime: new Date().toISOString(),
            IsActive: true,
            ModifiedBy: props.loggedInUser,
            ProducingAreaCode: newValue.ProducingAreaCode ? newValue.ProducingAreaCode : oldValue.ProducingAreaCode,
            OldShortName: newValue.OldShortName ? newValue.OldShortName : oldValue.oldShortName,
            ShortName: newValue.ShortName ? newValue.ShortName : oldValue.ShortName,
            CreatedDateTime: oldValue.CreatedDateTime,
            CommodityCode: newValue.CommodityCode ? newValue.CommodityCode : oldValue.CommodityCode,
            SummaryStatisticsShortNameId:oldValue.SummaryStatisticsShortNameId
        };
        props.authService.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.POST_SUMMARY_STATISTICS, 'POST', payload, {
                overrideRequestMapping: true,
                loadingMessage: 'Updating Short Name...'
            })
                .then((res) => {
                    setMessageDialog({
                        showMessage: true,
                        message: res.Message,
                        type: '',
                        severity: 'success'
                    });
                    onCancel();
                    getData();
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        type: '',
                        severity: 'error'
                    });
                });
        });
    };
    const onAdd = () => {
        let payload = {
            CreatedBy: props.loggedInUser,
            ModifiedDateTime: new Date().toISOString(),
            IsActive: true,
            ModifiedBy: props.loggedInUser,
            ProducingAreaCode: producingAreaAddValue.value,
            CommodityCode: commodityAdd.value,
            OldShortName: oldShortNameDialog.value,
            ShortName: newShortNameDialog.value,
            CreatedDateTime: new Date().toISOString(),
            SummaryStatisticsShortNameId:null
        };

        props.authService.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.POST_SUMMARY_STATISTICS, 'POST', payload, {
                overrideRequestMapping: true,
                loadingMessage: 'Adding new short names...'
            })
                .then((res) => {
                    setMessageDialog({
                        showMessage: true,
                        message: res.Message,
                        type: '',
                        severity: 'success'
                    });
                    onCancel();
                    getData();
                })
                .catch(() => {
                    let message = 'Something happened. but I am not sure if it is good or bad!';
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        type: '',
                        severity: 'error'
                    });
                });
        });
    };
    const onDelete = (deleteVal) => {
        let payload = {
            SummaryStatisticsShortNameId: deleteVal.SummaryStatisticsShortNameId
        };
        props.authService.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.POST_SUMMARY_STATISTICS, 'Delete', payload, {
                overrideRequestMapping: true,
                loadingMessage: 'Deleting Summary Statistics'
            })
                .then((_res) => {
                    let updatedData = props.summaryStatsList.filter(
                        (item) => item.SummaryStatisticsShortNameId !== deleteVal.SummaryStatisticsShortNameId
                    );
                    props.setSummaryStatsData(updatedData);
                    setMessageDialog({
                        showMessage: true,
                        message: 'Summary Statistics deleted successfully!',
                        type: '',
                        severity: 'success'
                    });
                    getData();
                    onCancel();
                })
                .catch((error) => {
                    let message = error.ErrorMessage || 'Something happened. but I am not sure if it is good or bad!';
                    setMessageDialog({
                        showMessage: true,
                        message: message,
                        type: '',
                        severity: 'error'
                    });
                });
        });
    };
    const handleClose = (_event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageDialog({ showMessage: false });
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const getColumn = (value, row) => {
        for (let key in row) {
            if (row[key] === value) {
                return key;
            }
        }
    };
    const handleOnChangeOfProducingArea = (option) => {
        if (option === null) {
            setProducingAreaFilter();
        } else {
            setProducingAreaFilter(option);
        }
    };

    const onCancel = () => {
        setAddMode(false);
        setCommodity('');
        setOldShortName('');
        setProducingAreaFilter([]);
    };

    const handleCommodity = (commodity) => {
        setCommodity(commodity);
    };

    const { classes } = props;

    return (
        <DrcPanel
            style={{ maxWidth: '100%', border: 'none', boxShadow: '0 2px 4px 0 #C3C3C3', marginBottom: '0px' }}
            className={props.classes.layout}
        >
            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={6} sm={5} md={4} lg={3}>
                    <DrcSelect
                        label="Producing Area"
                        options={producingAreaByUser}
                        value={producingAreaFilter}
                        placeholder={PRODUCING_AREA_PLACEHOLDER}
                        onChange={handleOnChangeOfProducingArea}
                        isClearable={true}
                    />
                </Grid>
                <Grid item xs={6} sm={5} md={4} lg={3}>
                    <DrcSelect
                        label={'Select Commodity'}
                        options={berryTypes}
                        value={commodity}
                        isClearable={true}
                        placeholder={'Please Select Commodity'}
                        onChange={handleCommodity}
                    />
                </Grid>

                <Grid item xs={6} sm={5} md={4} lg={3}>
                    <DrcSelect
                        label="New Short Name"
                        options={newShortNameOption}
                        isClearable={true}
                        value={newShortName}
                        placeholder="Please select New Short Name"
                        onChange={(e) => setNewShortName(e)}
                    />
                </Grid>
                <Grid item xs={6} sm={5} md={4} lg={3}>
                    <DrcSelect
                        label="Old Short Name"
                        options={oldShortNameOption}
                        isClearable={true}
                        value={oldShortName}
                        placeholder="Please select Old Short Name"
                        onChange={(e) => setOldShortName(e)}
                    />
                </Grid>
            </Grid>

            <DrcPageDataMaintenance
                classes={classes}
                className={classes.dialog}
                addBtn={true}
                fullWidth={true}
                data={formattedData}
                type={PAGE_TITLE}
                readOnly={false}
                columns={columns}                
                addText="Create New"
                onAdd={onAdd}
                onEdit={onEdit}
                onDelete={onDelete}
                settings={{
                    EnableExport: false,
                    EnableEdit: true,
                    EnableDelete: true,
                    OverrideAdd: () => {
                        setAddMode(true);
                    }
                }}
                textOptions={{
                    AddBtn: 'Add New'
                }}                
            />

            <DrcDialog
                open={AddMode}
                title={'Add: Summary Statistics Short Name'}
                buttons={
                    <div>
                        <DrcButton isSecondary onClick={onCancel} floatRight>
                            Cancel
                        </DrcButton>
                        <DrcButton
                            isPrimary
                            floatRight
                            onClick={onAdd}
                            disabled={!producingAreaAddValue || !commodityAdd || !oldShortNameDialog || !newShortNameDialog}
                        >
                            Save
                        </DrcButton>
                    </div>
                }
            >
                <hr />
                <>
                    <div className="row" style={{ margin: '1rem', padding: ' 0 .5rem' }}>
                        <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                            <DrcSelect
                                label="Producing Area"
                                options={producingAreaByUser}
                                value={producingAreaAddValue}
                                placeholder={PRODUCING_AREA_PLACEHOLDER}
                                onChange={(e) => setProducingAreaAddValue(e)}
                            />
                        </div>
                        <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                            <DrcSelect
                                label={'Select Commodity'}
                                options={berryTypes}
                                value={commodityAdd}
                                isClearable={true}
                                placeholder={'Please Select Commodity'}
                                onChange={(e) => setCommodityAdd(e)}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ margin: '1rem', padding: ' 0 .5rem' }}>
                        <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                            <DrcSelect
                                label="Old Short Name"
                                options={oldShortNameOption}
                                isClearable={true}
                                value={oldShortNameDialog}
                                placeholder="Please select Old Short Name"
                                onChange={(e) => setOldShortNameDialog(e)}
                            />
                        </div>
                        <div style={{ minWidth: '20rem', padding: '0 .5rem' }}>
                            <DrcSelect
                                label="New Short Name"
                                options={newShortNameOption}
                                isClearable={true}
                                value={newShortNameDialog}
                                placeholder="Please select New Short Name"
                                onChange={(e) => setNewShortNameDialog(e)}
                            />
                        </div>
                    </div>
                </>
            </DrcDialog>

            <Snackbar open={messageDialog.showMessage} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={messageDialog.severity}>
                    {messageDialog.message}
                </Alert>
            </Snackbar>
        </DrcPanel>
    );
};

function mapStateToProps(state) {
    return {
        pageTitle: state.rootReducer.pageTitle,
        loggedInUser: state.masterReducer.loggedInUser,
        summaryStatsList: state.summaryStatsReducer.summaryStatsList,
        userProducingArea: state.summaryStatsReducer.userProducingArea,
        producingArea: state.overrideReducer.producingArea
    };
}

export default connect(mapStateToProps, { setSummaryStatsData, setUserProducingArea })(withOktaAuth(withStyles(styles)(SummaryStatistics)));
