import { DriscollsDataTable, DriscollsVContainer } from '@driscollsinc/style-guide-web2.0';
import styles from './DispositionTable.module.scss';

const DispositionTable = ({ rows, columns }) => {
    return (
        <DriscollsVContainer noPadding className={styles.container}>
            <DriscollsDataTable
                data={rows}
                columns={columns}
                hidePagination={true}
                hideFilters={true}
                isLoading={false}
                textFilterPlaceholder={false}
            />
            ;
        </DriscollsVContainer>
    );
};

export default DispositionTable;
