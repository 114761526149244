import React from 'react';
import Image from 'react-image-webp';

class DrcImage extends React.Component {
    render() {
        return <Image title={this.props.title} src={this.props.src} webp={this.props.webp} className={this.props.className} style={this.props.style} alt={this.props.alt} />;
    }
}

export default DrcImage;
