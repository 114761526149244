import {
    DriscollsDataTableExpander,
    DirectionEnum,
    DriscollsDataTable,
    DriscollsMobileList,
    DriscollsComponentSwitcher
} from '@driscollsinc/style-guide-web2.0';
import { UserManageColumn } from '../../enums/user';
import { TAssignments, TLoadOptions } from '../../types/users';
import AssignmentCard from './AssignmentCard/AssignmentCard';
import { useEffect, useState } from 'react';

interface IUserManagementGrid {
    loading: boolean;
    data: TAssignments[];
    totalItem: number;
    itemPerPage: number;
    onPageChanged: (pageNumber: number) => void;
    selectedPage: number;
    loadOptions: TLoadOptions;
}

const UserManagementGrid = ({ loading, data, totalItem, itemPerPage, onPageChanged, selectedPage, loadOptions }: IUserManagementGrid) => {
    const [tableData, setTableData] = useState([]);

    const formatData = (data: TAssignments[]) =>
        data.map((row) => ({
            Id: row.Id,
            Site: row.Site.Name,
            Region: row.Region.Name,
            Warehouses: row.Warehouses
        }));

    useEffect(() => {
        setTableData(formatData(data));
    }, []);

    const columns = [
        {
            id: 'expander',
            size: 40,
            enablePinning: true,
            header: () => null,
            cell: ({ row }: any) => {
                return (
                    <>
                        <DriscollsDataTableExpander row={row} />
                    </>
                );
            }
        },
        {
            accessorKey: UserManageColumn.SITE,
            header: 'Site Name',
            enableColumnFilter: false,
            enablePinning: false
        },
        {
            accessorKey: UserManageColumn.REGION,
            header: 'Region',
            enableColumnFilter: false,
            enablePinning: false
        }
    ];

    const renderExpandedContent = (_id: string, rowData?: TAssignments) => {
        return <AssignmentCard rowData={rowData} loadOptions={loadOptions} />;
    };

    return (
        <DriscollsComponentSwitcher
            direction={DirectionEnum.width}
            componentList={[
                {
                    breakPoint: 799,
                    component: (
                        <DriscollsMobileList
                            isLoading={loading}
                            data={tableData}
                            renderExpandedComponent={renderExpandedContent}
                            selectedPage={selectedPage}
                            onPageChange={onPageChanged}
                            itemsPerPage={itemPerPage}
                            totalItems={totalItem}
                            paginationText={`Total No of Users: ${totalItem}`}
                            hideKeys={['Warehouses', 'Id']}
                        />
                    )
                },
                {
                    breakPoint: 800,
                    component: (
                        <DriscollsDataTable
                            isLoading={loading}
                            selectedPage={selectedPage}
                            columns={columns}
                            data={tableData}
                            onPageChange={onPageChanged}
                            itemsPerPage={itemPerPage}
                            renderExpandedContent={renderExpandedContent}
                            totalItems={totalItem}
                            paginationText={`Total No of Users: ${totalItem}`}
                        />
                    )
                }
            ]}
        />
    );
};

export default UserManagementGrid;
