import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Store } from './dota/data/store';
import AllMiddleware from './dota/data/middleware';
import Application from './Application';

declare global {
    interface Window {
        config: any;
    }
}

const root: ReactDOM.Root = ReactDOM.createRoot(document.getElementById('root'));

const renderApp = () =>
    root.render(
        <Provider store={Store}>
            <Application/>
        </Provider>
    );

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./Application', renderApp);
}

AllMiddleware.ConfigureAllMiddleware();

renderApp();
