import SiteEntityInfo from './SiteEntity';
import { DriscollsLegend, DriscollsTranslatedValue, DriscollsVContainer, FaIcons, IconSize } from '@driscollsinc/style-guide-web2.0';
import styles from './SiteMappingGrid.module.scss';

const SitesEntities = ({ entities }) => {
    return (
        <DriscollsLegend
            show
            title={
                <>
                    <FaIcons.FaWarehouse size={IconSize.SMALLER} className={styles.theIcon} />
                </>
            }
        >
            <DriscollsVContainer noPadding>
                <SiteEntityInfo key={`${entities.Name}-${entities.Id}`} entity={entities} />
            </DriscollsVContainer>
        </DriscollsLegend>
    );
};

export default SitesEntities;
