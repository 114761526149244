import {
    DriscollsHContainer,
    DriscollsTypography,
    DriscollsTypographyStyles,
    DriscollsTypographyEnum,
    IconSize,
    FaIcons,
    DriscollsVContainer
} from '@driscollsinc/style-guide-web2.0';
import style from './Warehouse.module.scss';
import AddUsers from './add/AddUsers';
import { TLoadOptions } from '../../../../types/users';

interface IWarehouseHeader {
    name: string;
    warehouseId: string;
    loadOptions: TLoadOptions;
}

const WarehouseHeader = ({ name, warehouseId, loadOptions }: IWarehouseHeader) => {
    return (
        <DriscollsVContainer noPadding className={style.header}>
            <DriscollsHContainer className={style.info} noPadding>
                <div className={style.headerName}>
                    <span className={style.entityIcon}>
                        <FaIcons.FaWarehouse size={IconSize.SMALL} />
                    </span>
                    <DriscollsTypography type={DriscollsTypographyEnum.P} typographyStyle={DriscollsTypographyStyles.SUBTITLE5} ellipsis>
                        {name}
                    </DriscollsTypography>
                </div>
            </DriscollsHContainer>
            <AddUsers loadOptions={loadOptions} warehouseId={warehouseId} />
        </DriscollsVContainer>
    );
};

export default WarehouseHeader;
