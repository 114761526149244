const handleRouting = (path) => {
    if (path === '/Main/' || path === '/Bypass/Location/') {
        return 'Bypass Location';
    } else if (path === '/Bypass/Quartile/') {
        return 'Bypass Quartile';
    } else if (path === '/Override/') {
        return 'Override';
    } else if (path === '/Notification/') {
        return 'Notification';
    } else if (path === '/PointMatrix/Create/') {
        return 'Point Matrix Create';
    } else if (path === '/PointMatrix/Preview/') {
        return 'Point Matrix Preview';
    } else if (path === '/Maintenance/') {
        return 'QA Manager';
    } else if (path === '/DistrictGroup/') {
        return 'District Group';
    } else if (path === '/Disposition/DispositionOptions/') {
        return 'Disposition Options';
    } else if (path === '/Disposition/GrowerPreferences/') {
        return 'Grower Preferences';
    } else if (path === '/RejectionThreshold/') {
        return 'Rejection Threshold';
    } else if (path === '/ProducingAreaRegion/') {
        return 'Producing Area Region';
    } else if (path === '/SummaryStatistics/') {
        return 'Summary Statistics';
    }
};

const subTabRouting = (path) => {
    if (path === '/PointMatrix/Create/') {
        return 'Point Matrix Create';
    } else if (path === '/PointMatrix/Preview/') {
        return 'Point Matrix Preview';
    }
};

const subTabByPassRouting = (path) => {
    if (path === '/Bypass/Location/') {
        return 'Bypass Location';
    } else if (path === '/Bypass/Quartile/') {
        return 'Bypass Quartile';
    }
};

const subTabDispositionRouting = (path) => {
    if (path === '/Disposition/DispositionOptions/') {
        return 'Disposition Options';
    } else if (path === '/Disposition/GrowerPreferences/') {
        return 'Grower Preferences';
    }
};

const routingOnTabChange = (changingTabIndex, navigate) => {
    if (changingTabIndex === 0) {
        navigate('/Bypass/Location/');
        return 'Bypass Location';
    } else if (changingTabIndex === 1) {
        navigate('/Override/');
        return 'Override';
    } else if (changingTabIndex === 2) {
        navigate('/Notification/');
        return 'Notification';
    } else if (changingTabIndex === 3) {
        navigate('/PointMatrix/Create/');
        return 'Point Matrix Create';
    } else if (changingTabIndex === 4) {
        navigate('/Maintenance/');
        return 'QA Manager';
    } else if (changingTabIndex === 5) {
        navigate('/DistrictGroup/');
        return 'District Group';
    } else if (changingTabIndex === 6) {
        navigate('/RejectionThreshold/');
        return 'Rejection Threshold';
    } else if (changingTabIndex === 7) {
        navigate('/Disposition/DispositionOptions/');
        return 'Disposition Options';
    } else if (changingTabIndex === 8) {
        navigate('/ProducingAreaRegion/');
        return 'Producing Area Region';
    } else if (changingTabIndex === 9) {
        navigate('/SummaryStatistics/');
        return 'Summary Statistics';
    }
};
export { handleRouting, routingOnTabChange, subTabRouting, subTabByPassRouting, subTabDispositionRouting };
