import React from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const styles = (theme) => ({
    list: {
        minWidth: '200px'
    },
    icon: {
        minWidth: '24px',
        color: theme.light.text.primary,
        fill: theme.light.text.primary,
        [theme.darkTheme]: {
            color: theme.dark.text.primary,
            fill: theme.dark.text.primary,
        }
    },
    link: {
        padding: '0',
        fontSize: '1.2rem',
        textDecoration: 'none',
        color: theme.light.text.primary,
        [theme.darkTheme]: {
            color: theme.dark.text.primary
        }
    },
    iconLink: {
        padding: '0 0 0 6px'
    }
});

class DrcLinkItem extends React.Component {
    render() {
        const { title, icon, className, classes, link, toggleDrawer } = this.props;
        var linkItem = (
            <ListItem button key={title} disableRipple={true} className={`${classes.list} ${className}`}>
                {icon ? <ListItemIcon className={classes.icon}>{icon}</ListItemIcon> : null}
                <ListItemText disableTypography={true} className={`${classes.link} ${icon ? classes.iconLink : ''}`} primary={title} />
            </ListItem>
        );
        if ((link.url || '').length > 0) {
            return (
                <NavLink key={link.url} to={link.url} className={classes.link} onClick={toggleDrawer(false)}>
                    {linkItem}
                </NavLink>
            );
        } else if ((link.href || '').length > 0) {
            return (
                <a key={link.href} href={link.href} target={link.target || '_blank'} className={classes.link} onClick={toggleDrawer(false)}>
                    {linkItem}
                </a>
            );
        }
        return <React.Fragment>{linkItem}</React.Fragment>;
    }
}

export default withStyles(styles)(DrcLinkItem);
