import {
    ApplicationPageTitle,
    ApplicationPage,
    DriscollsSelect,
    DriscollsTranslatedValue,
    DriscollsVContainer,
    useUtilities
} from '@driscollsinc/style-guide-web2.0';

import styles from './UserManagement.module.scss';
import { NAMESPACE } from '../../../i18n';
import UserManagementGrid from '../../components/user/UserManagementGrid';
import { USERS } from './mockdata';
import useGetUsers from '../../hooks/useGetUsers';
import { useCallback } from 'react';

const UserManagement = () => {
    const { fetchOktaUsersList } = useGetUsers();
    const { debounce } = useUtilities();

    const loadOptions = useCallback(
        debounce((inputValue: string, callback: (options: Array<{ label: string; value: string }>) => void) => {
            fetchOktaUsersList(inputValue).then((options) => {
                callback(options);
            });
        }, 500),
        []
    );

    return (
        <ApplicationPage className={styles.qmpApplicationPage}>
            <ApplicationPageTitle
                title={{ text: 'User Management', nameSpace: NAMESPACE.COMMON }}
                description={{ text: 'Manage the access of QA managers of the internal users', nameSpace: NAMESPACE.USER_MANAGEMENT }}
            />
            <DriscollsVContainer>
                <DriscollsSelect
                    placeholder={<DriscollsTranslatedValue value="Filter By Site" nameSpacing={NAMESPACE.USER_MANAGEMENT} />}
                    className={styles.selectField}
                    containerClassName={styles.selectContainer}
                    options={[{ value: 'Portugal', label: 'Portugal' }]}
                />
                <UserManagementGrid
                    data={USERS.Assignments}
                    selectedPage={1}
                    totalItem={Number(USERS.Total)}
                    onPageChanged={() => {}}
                    loading={false}
                    itemPerPage={10}
                    loadOptions={loadOptions}
                />
            </DriscollsVContainer>
        </ApplicationPage>
    );
};

export default UserManagement;
