import { Route, Routes } from 'react-router-dom';
import Sites from './Sites';
import { routes, routesName } from '../../routes/routes';
import CreateNewSite from './CreateNewSite';
import EditSiteInfo from './EditSiteInfo';

const SiteRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<Sites />} />
            <Route path={routes[routesName.CREATE_SITE_MAPPING].path} element={<CreateNewSite />} />
            <Route path={routes[routesName.EDIT_SITE_MAPPING].path} element={<EditSiteInfo />} />
        </Routes>
    );
};

export default SiteRoutes;
