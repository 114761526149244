import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import withStyles from '@material-ui/styles/withStyles';
import APIEndPoints from '../../services/api';
import { Middleware } from 'one-ring';
import { DrcButton, DrcDialog } from 'driscolls-react-components';
import { RANCH_OVERRIDE_DELETE } from '../../language/english';
import { getPayload } from '../../util/utils';

let styles = () => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: '800px',
            width: '100%'
        }
    }
});

const PAGE_TYPE = 'override';
const DeleteDialog = (props) => {
    const [dialogContent, setDialogContent] = useState({ ...props.content });

    useEffect(() => {
        setDialogContent(props.content);
    }, [props]);

    const handleDeleteLineItem = (deleteVal) => {
        let payload = getPayload(deleteVal);
        payload.IsDeleted = 1;
        props.authService.getAccessToken().then((token) => {
            Middleware.Send(PAGE_TYPE, token, APIEndPoints.OVERRIDE_DATA, 'POST', payload, { loadingMessage: 'Deleting ranch override...' })
                .then((_res) => {
                    let updatedData = props.overrideData.filter((item) => item.RanchNbr !== payload.RanchNbr);
                    props.updateOverrideData(updatedData, 'delete');
                    props.onSave('deleteYes', true, RANCH_OVERRIDE_DELETE);
                })
                .catch((error) => {
                    let message = error.data.response.data.message || 'Something happened. but I am not sure if it is good or bad!';
                    props.onSave('deleteYes', false, message);
                });
        });
    };

    const onDialogYes = () => {
        handleDeleteLineItem(dialogContent.editedValue);
    };

    const onDialogNo = () => {
        props.onSave('deleteNo');
    };
    const { classes } = props;
    return (
        <DrcDialog
            className={classes.dialog}
            title={dialogContent.dialogText}
            open={dialogContent.dialogOpen}
            buttons={
                <React.Fragment>
                    <DrcButton isPrimary onClick={onDialogYes}>
                        Accept Change
                    </DrcButton>
                    <DrcButton isSecondary onClick={onDialogNo}>
                        Oops, Just Kidding
                    </DrcButton>
                </React.Fragment>
            }
        />
    );
};

export default connect(null, null)(withOktaAuth(withStyles(styles)(DeleteDialog)));
