import DrcGlossaryLabel from '../Components/DrcGlossaryLabel';
import DrcGlossaryList from '../Components/DrcGlossaryList';
import { DuGlossaryUtilities } from 'driscolls-react-utilities';

var get = function(key) {
    return <DrcGlossaryLabel description={DuGlossaryUtilities.GetDescription(key)}>{key}</DrcGlossaryLabel>;
};

var getGlossaryList = function() {
    return (
        <DrcGlossaryList>
            {DuGlossaryUtilities.Glossary.map((glossaryObj) => {
                return { key: glossaryObj.key, value: glossaryObj.longDescription };
            })}
        </DrcGlossaryList>
    );
};

const DrcGlossaryUtilities = {
    ...DuGlossaryUtilities,
    Get: get,
    GetGlossaryList: getGlossaryList
};

export default DrcGlossaryUtilities;
