import React, { Component } from 'react';
import DrcPanel from './DrcPanel';
import DrcLegend from './DrcLegend';
import DrcIconButton from './DrcIconButton';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { withStyles } from '@material-ui/core/styles';

var styles = (theme) => ({
    root: {
        borderRadius: '.5rem',
        boxShadow: '8px 8px 6px -6px #777',
        padding: '0 1rem'
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 0,
        marginBottom: 0,
        borderBottom: 'none',
        padding: 0
    },
    header: {
        fontSize: '1.3rem',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    content: {
        borderTop: '1px solid #777',
        padding: '0.5rem 0rem'
    },
    iconButton: {
        border: 'none',
        '&:hover': {
            border: 'none'
        }
    }
});

class DrcCollapsiblePanel extends Component {
    state = {
        collapsed: true
    };

    showHideContent = (e) => this.setState({ collapsed: !this.state.collapsed });

    render() {
        const { maxWidth, header, children, rootStyles, headerStyles, contentStyles, classes, isSecondary, isPrimary, iconButtonStyles } = this.props;

        return (
            <DrcPanel maxWidth={maxWidth || '100%'} className={`${classes.root} ${rootStyles}`}>
                <DrcLegend className={`${classes.headerContainer}`}>
                    <div onClick={() => this.setState({ collapsed: !this.state.collapsed })} className={`${classes.header} ${headerStyles}`}>
                        {header || 'Details'}
                    </div>
                    <div>
                        <DrcIconButton
                            className={`${classes.iconButton} ${iconButtonStyles}`}
                            isSecondary={isSecondary || true}
                            isPrimary={isPrimary}
                            onClick={() => this.setState({ collapsed: !this.state.collapsed })}
                        >
                            {this.state.collapsed ? <ExpandMore style={{ fontSize: 25 }} /> : <ExpandLess style={{ fontSize: 25 }} />}
                        </DrcIconButton>
                    </div>
                </DrcLegend>
                {!this.state.collapsed && <div className={`${classes.content} ${contentStyles}`}>{children}</div>}
            </DrcPanel>
        );
    }
}

export default withStyles(styles)(DrcCollapsiblePanel);
