const icons = {
    Black_Small_Png: require('../Images/Black_Small.png'),
    Blue_Small_Png: require('../Images/Blue_Small.png'),
    Rasp_Small_Png: require('../Images/Rasp_Small.png'),
    Straw_Small_Png: require('../Images/Straw_Small.png'),
    Black_Medium_Png: require('../Images/Black_Medium.png'),
    Blue_Medium_Png: require('../Images/Blue_Medium.png'),
    Rasp_Medium_Png: require('../Images/Rasp_Medium.png'),
    Straw_Medium_Png: require('../Images/Straw_Medium.png')
};

export default icons;
