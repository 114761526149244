export enum routesName {
    INITIALIZE_APPLICATION = 'initializeApplication',
    USER_MANAGEMENT = 'users',
    SITES = 'sites',
    CREATE_SITE_MAPPING = 'createSiteMapping',
    EDIT_SITE_MAPPING = 'editSite'
}

export const routes = {
    [routesName.INITIALIZE_APPLICATION]: {
        path: '/InitializeApplication/',
        getRoute: () => '/InitializeApplication/'
    },

    [routesName.USER_MANAGEMENT]: {
        path: 'users',
        getRoute: () => 'users'
    },
    [routesName.SITES]: {
        path: 'sites',
        getRoute: () => 'sites'
    },
    [routesName.CREATE_SITE_MAPPING]: {
        path: 'createSite',
        getRoute: () => 'createSite'
    },
    [routesName.EDIT_SITE_MAPPING]: {
        path: 'editSite/:id',
        getRoute: (siteId: string) => `editSite/${siteId}`
    }
};
