import {
    DirectionEnum,
    DriscollsComponentSwitcher,
    DriscollsDataTable,
    DriscollsDataTableExpander,
    DriscollsMobileList,
    DriscollsTrashButton,
    DriscollsEditButton
} from '@driscollsinc/style-guide-web2.0';
import { UserManageColumn } from '../../enums/user';
import { TSiteAssignments } from '../../types/siteMapping';
import SitesSubRow from './SiteSubRow';
import { useNavigate } from 'react-router-dom';
import { routes, routesName } from '../../routes/routes';

interface ISiteMappingGrid {
    loading: boolean;
    data: TSiteAssignments[];
    totalItem: number;
    itemPerPage: number;
    onDelete: (user: TSiteAssignments) => void;
    onPageChanged: (pageNumber: number) => void;
    selectedPage: number;
}

const SitesMappingGrid = (props: ISiteMappingGrid) => {
    const { loading, data, totalItem, itemPerPage, onPageChanged, selectedPage, onDelete } = props;
    const navigate = useNavigate();
    const formatData = (data: TSiteAssignments[]) => data.map((row) => ({ ...row, Site: row.Site.Name, Region: row.Region.Name }));
    const columns = [
        {
            id: 'expander',
            size: 120,
            enablePinning: true,
            header: () => null,
            cell: ({ row }: any) => {
                return (
                    <>
                        <DriscollsDataTableExpander row={row} />
                        <DriscollsEditButton onClick={() => navigate(routes[routesName.EDIT_SITE_MAPPING].getRoute(row.id))} />
                        <DriscollsTrashButton onClick={() => onDelete(row.id)} />
                    </>
                );
            }
        },
        {
            accessorKey: UserManageColumn.SITE,
            header: 'Site Name',
            enableColumnFilter: false,
            enablePinning: false
        },
        {
            accessorKey: UserManageColumn.REGION,
            header: 'Region',
            enableColumnFilter: false,
            enablePinning: false
        }
    ];

    const renderExpandedContent = (id: string, rowData?: any) => {
        return <SitesSubRow warehouses={rowData.Warehouses} />;
    };

    return (
        <DriscollsComponentSwitcher
            direction={DirectionEnum.width}
            componentList={[
                {
                    breakPoint: 799,
                    component: (
                        <DriscollsMobileList
                            isLoading={loading}
                            data={formatData(data)}
                            renderExpandedComponent={renderExpandedContent}
                            selectedPage={selectedPage}
                            onPageChange={onPageChanged}
                            itemsPerPage={itemPerPage}
                            totalItems={totalItem}
                            paginationText={`Total No of Sites: ${totalItem}`}
                            hideKeys={['Warehouses']}
                        />
                    )
                },
                {
                    breakPoint: 800,
                    component: (
                        <DriscollsDataTable
                            isLoading={loading}
                            manualPagination={true}
                            selectedPage={selectedPage}
                            columns={columns}
                            showHover={false}
                            data={formatData(data)}
                            onPageChange={onPageChanged}
                            itemsPerPage={itemPerPage}
                            renderExpandedContent={renderExpandedContent}
                            totalItems={totalItem}
                            textFilterPlaceholder={false}
                            paginationText={`Total No of Sites: ${totalItem}`}
                            enableDragAndDrop={false}
                            showColumnVisibility={false}
                            showDownloadButton={false}
                        />
                    )
                }
            ]}
        />
    );
};

export default SitesMappingGrid;
