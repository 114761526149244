import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { DuAuthenticationUtilities } from 'driscolls-react-utilities';
import DrcHeader from './DrcHeader';

class DrcSecureHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allowedLinks: this.props.allowAllLinks ? this.props.allLinks || [] : []
        };

        this.checkAuthentication = this.checkAuthentication.bind(this);
    }

    checkAuthentication() {
        if (!this.props.authState.isAuthenticated) {
            if (this.state.allowedLinks.length > 0) {
                this.setState({ allowedLinks: [] });
            }

            return;
        }

        this.props.authService.getAccessToken().then((token) => {
            if (!token || token.length <= 0) {
                if (this.state.allowedLinks.length > 0) {
                    this.setState({ allowedLinks: [] });
                }
            } else {
                var allowedLinks = [];

                var matchedLinks = this.props.allLinks.filter((l) => {
                    if ((l.requiresGroups || []).length > 0) {
                        return l.requiresGroups.some((rg) => DuAuthenticationUtilities.IsInGroup(token, rg));
                    }

                    return true;
                });

                if (DuAuthenticationUtilities.IsInGroup(token, window.config.OKTA_ADMIN_GROUPS || [])) {
                    allowedLinks = matchedLinks;
                } else if (DuAuthenticationUtilities.IsInGroup(token, window.config.OKTA_REGULAR_GROUPS || [])) {
                    allowedLinks = matchedLinks.filter((link) => !link.requiresAdmin);
                    allowedLinks = allowedLinks.map((link) => {
                        link.subLinks = (link.subLinks || []).filter((subLink) => !subLink.requiresAdmin);
                        return link;
                    });
                } else if (DuAuthenticationUtilities.IsInGroup(token, window.config.OKTA_READ_ONLY_GROUPS || [])) {
                    allowedLinks = matchedLinks.filter((link) => !link.requiresAdmin && !link.requiresRegular);
                    allowedLinks = allowedLinks.map((link) => {
                        link.subLinks = (link.subLinks || []).filter((subLink) => !subLink.requiresAdmin && !subLink.requiresRegular);
                        return link;
                    });
                }

                if (this.state.allowedLinks.length !== allowedLinks.length) {
                    this.setState({ allowedLinks });
                }
            }
        });
    }

    componentDidMount() {
        this.checkAuthentication();
    }

    componentDidUpdate() {
        this.checkAuthentication();
    }

    render() {
        const { children, title, noLogo, style, fullWidth, logo, className, showFilterControl, showSavedFiltersPopup, anchor } = this.props;

        return (
            <DrcHeader
                title={title}
                noLogo={noLogo}
                showSavedFiltersPopup={showSavedFiltersPopup}
                allLinks={this.state.allowedLinks}
                style={style}
                showFilterControl={showFilterControl}
                fullWidth={fullWidth}
                logo={logo}
                className={className}
                anchor={anchor}
            >
                {children}
            </DrcHeader>
        );
    }
}

export default withOktaAuth(DrcSecureHeader);
