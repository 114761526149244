const appLanguages: string[] = ['en', 'es', 'fr'];

export enum sessionStorageEnum {
    QMP_USER_LANG = 'QMP_UserLang'
}

/**
 * Gets the default language to use based on browser and session storage.
 *
 * Checks if the browser language matches supported languages, and returns
 * that if so. If not, returns 'en' as the default.
 * Also check sessionStorage for a language override.
 *
 * @returns {string} The language code to use as the default. Either the
 * browser language ('en', 'es', 'fr'), or 'en' if browser language is not supported.
 */

export const defaultLanguageHandler = (): string => {
    const browserLang: string = navigator.language.substring(0, 2);

    const defaultBrowserLang: string = appLanguages.includes(browserLang) ? browserLang : 'en';

    const sessionStorageLang: string = sessionStorage.getItem(sessionStorageEnum.QMP_USER_LANG);

    return sessionStorageLang || defaultBrowserLang;
};
