import { DuAuthenticationUtilities } from '../../legacy/DriscollsReactUtilities';

const useGetApiTokens = () => {
    const getTokenAndId = async (oktaAuth) => {
        const token = await DuAuthenticationUtilities.getAccessTokenFromAuthenticationProvider(oktaAuth);
        const userOktaId = DuAuthenticationUtilities.GetOktaId(token);
        const userEmail = DuAuthenticationUtilities.GetUserId(token);
        return { token, userOktaId, userEmail };
    };

    const OKTA_TOKEN_STORAGE_KEY = 'okta-token-storage';
    const TOKEN_TYPE_KEY = 'tokenType';
    const ACCESS_TOKEN_KEY = 'accessToken';

    const getAuthToken = () => {
        const oktaTokenStorage = JSON.parse(localStorage.getItem(OKTA_TOKEN_STORAGE_KEY) || '');
        if (oktaTokenStorage !== '') {
            const accessToken = oktaTokenStorage[ACCESS_TOKEN_KEY];
            const tokenType = accessToken[TOKEN_TYPE_KEY];
            const token = accessToken[ACCESS_TOKEN_KEY];
            return `${tokenType} ${token}`;
        }
        return '';
    };

    return { getTokenAndId, getAuthToken };
};

export default useGetApiTokens;
