import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DrcTooltip from './DrcTooltip';

const styles = (theme) => ({
    tooltip: {
        fontSize: '1rem'
    }
});

class DrcGlossaryLabel extends React.Component {
    render() {
        const { classes, description, children } = this.props;
        return (
            <DrcTooltip tipText={description} className={classes.tooltip}>
                <span>{children}</span>
            </DrcTooltip>
        );
    }
}

export default withStyles(styles)(DrcGlossaryLabel);
