import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    check: {
        color: theme.light.variant.secondary,
        [theme.darkTheme]: {
            color: theme.dark.variant.secondary
        }
    }
});

class DrcCheckbox extends React.Component {
    render() {
        const { className, classes, isSecondary, style, checked, onChange, disabled, required } = this.props;
        var isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');

        return (
            <Checkbox
                checked={checked}
                onChange={onChange}
                className={`${classes.check} ${className}`}
                style={style}
                color={isSecondary ? (isDarkTheme.matches ? 'primary' : 'secondary') : 'default'}
                disabled={disabled || false}
                required={required || false}
            />
        );
    }
}

export default withStyles(styles)(DrcCheckbox);
