import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DrcGlobalStyle from './DrcGlobalStyles';
import DrcThemeUtilities from '../Utilities/DrcThemeUtilities';
import { DuThemeUtilities } from 'driscolls-react-utilities';

const defaultFontFamily = [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
].join(',');

class DrcThemeProvider extends Component {
    render() {
        const { children, theme, fontFamily, globalStyle } = this.props;

        var muiTheme = theme || DrcThemeUtilities.CreateSimpleTheme(DuThemeUtilities.DefaultColors.primary.green);

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiThemeProvider theme={muiTheme}>
                    {children}
                    {globalStyle || <DrcGlobalStyle fontFamily={fontFamily || defaultFontFamily} />}
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        );
    }
}

export default DrcThemeProvider;
