import { DriscollsDataTable } from '@driscollsinc/style-guide-web2.0';
import style from './SimpleDataTable.module.scss';

const SimpleDataTable = ({ columns, data }) => {
    const tableProps = {
        hideFilters: true,
        enablePinning: false,
        enableDragAndDrop: false,
        isLoading: false,
        textFilterPlaceholder: false,
        hidePagination: true
    };

    return (
        <div className={style.tableContainer}>
            <DriscollsDataTable data={data} columns={columns} {...tableProps} />
        </div>
    );
};

export default SimpleDataTable;
