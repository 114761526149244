import React from 'react';
import DrcButton from './DrcButton';
import CloseIcon from '@material-ui/icons/Close';

class DrcFilterItem extends React.Component {
    constructor(props) {
        super(props);

        this.onRemove = this.onRemove.bind(this);
    }

    onRemove() {
        this.props.onRemove(this.props.type);
    }

    render() {
        const { name, label } = this.props;

        return (
            <DrcButton
                isSecondary
                style={{
                    marginLeft: '0',
                    marginRight: '15px',
                    borderRadius: '18px',
                    padding: '3px 5px 1px 10px',
                    textTransform: 'none'
                }}
                onClick={this.onRemove}
            >
                {name}: {label} <CloseIcon style={{ height: '20px', marginTop: '-1px' }} />
            </DrcButton>
        );
    }
}

export default DrcFilterItem;
