import React from 'react';
import DrcImage from '../Components/DrcImage';
import DrcIconSrc from './DrcIconSrc';
import { DuIconSrc } from 'driscolls-react-utilities';

const icons = {
    Black_Small: <DrcImage src={DuIconSrc.Black_Small_Png} webp={DrcIconSrc.Black_Small_Webp} alt="Blackberry" />,
    Blue_Small: <DrcImage src={DuIconSrc.Blue_Small_Png} webp={DrcIconSrc.Blue_Small_Webp} alt="Blueberry" />,
    Rasp_Small: <DrcImage src={DuIconSrc.Rasp_Small_Png} webp={DrcIconSrc.Rasp_Small_Webp} alt="Raspberry" />,
    Straw_Small: <DrcImage src={DuIconSrc.Straw_Small_Png} webp={DrcIconSrc.Straw_Small_Webp} alt="Strawberry" />,
    Black_Medium: <DrcImage src={DuIconSrc.Black_Medium_Png} webp={DrcIconSrc.Black_Medium_Webp} alt="Blackberry" />,
    Blue_Medium: <DrcImage src={DuIconSrc.Blue_Medium_Png} webp={DrcIconSrc.Blue_Medium_Webp} alt="Blueberry" />,
    Rasp_Medium: <DrcImage src={DuIconSrc.Rasp_Medium_Png} webp={DrcIconSrc.Rasp_Medium_Webp} alt="Raspberry" />,
    Straw_Medium: <DrcImage src={DuIconSrc.Straw_Medium_Png} webp={DrcIconSrc.Straw_Medium_Webp} alt="Strawberry" />
};

icons.GetSmallBerryIcon = function(berryType) {
    var berryIcon = null;

    switch (berryType) {
        case 1:
        case '1':
        case 'BLACK':
            berryIcon = icons.Black_Small;
            break;
        case 2:
        case '2':
        case 'BLUE':
            berryIcon = icons.Blue_Small;
            break;
        case 3:
        case '3':
        case 'RASP':
            berryIcon = icons.Rasp_Small;
            break;
        case 4:
        case '4':
        case 'STRAW':
            berryIcon = icons.Straw_Small;
            break;
        default:
            break;
    }

    return berryIcon;
};

icons.GetBerryIcon = function(berryType, isSmall = true) {
    var berryIcon = null;

    switch (berryType) {
        case 1:
        case '1':
        case 'BLACK':
            berryIcon = isSmall ? icons.Black_Small : icons.Black_Medium;
            break;
        case 2:
        case '2':
        case 'BLUE':
            berryIcon = isSmall ? icons.Blue_Small : icons.Blue_Medium;
            break;
        case 3:
        case '3':
        case 'RASP':
            berryIcon = isSmall ? icons.Rasp_Small : icons.Rasp_Medium;
            break;
        case 4:
        case '4':
        case 'STRAW':
            berryIcon = isSmall ? icons.Straw_Small : icons.Straw_Medium;
            break;
        default:
            break;
    }

    return berryIcon;
};

export default icons;
