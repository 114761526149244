/**
 * NOTE: DrcSelect take options as an array of [{label,value},{label,value},{label,value}....]
 * whereas the DrcChips take options as an array of [{key,label},{key,label},{key,label}...]
 * Best option is to pass a prop name as options which contains array of objects including 3 properties key,label,value
 * i.e options=[{key:1,label:myChip1,value:myChip1},{key:2,label:myChip2,value:myChip2}...]
 * @Parameter chips : [{label,value}....] or [{key,label,value}...]
 * @Parameter options : [{key:1,label:myChip1,value:myChip1},{key:2,label:myChip2,value:myChip2}...]
 */
import React, { Component } from 'react';
import DrcChips from './DrcChips';
import DrcSelect from './DrcSelect';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    berrySelect: {
        '& label': {
            fontSize: '1rem'
        }
    },
    listlabel: {
        display: 'flex',
        alignItems: 'center',
        flex: 1
    }
});

class DrcChipsMultiSelect extends Component {
    state = {
        chips: this.props.chips || [], // If any default chip is required then pass an array of [{key:1,label:myChipValue}]
        chipsValues: (this.props.chips || []).map((obj) => obj.value),
        selectOptions: []
    };

    componentDidMount() {
        this.setState({ selectOptions: this.props.options.filter((opt) => !this.state.chipsValues.includes(opt.value)) });
    }

    componentDidUpdate(prevProps, prevState) {
        let chipsValues = (this.props.chips || []).map((obj) => obj.value);
        let selectOptions = this.props.options.filter((opt) => !chipsValues.includes(opt.value));

        if (this.state.chipsValues.join('') !== chipsValues.join('')) {
            this.setState({ chipsValues, chips: this.props.chips, selectOptions });
        }
    }

    handleSelectChange = (changedProps) => {
        let newChip = this.props.options.filter((obj) => obj.value === changedProps.value)[0];

        if (!newChip.key) {
            newChip.key = newChip.value;
        }

        this.setState(
            {
                selectOptions: this.state.selectOptions.filter((optn) => optn.value !== changedProps.value),
                chipsValues: [...this.state.chipsValues, changedProps.value],
                chips: [...this.state.chips, newChip]
            },
            () => {
                if (this.props.onSelectChange) {
                    this.props.onSelectChange(changedProps, this.state.chips);
                }
            }
        );
    };

    handleChipDelete = (chipToDelete) => {
        let chipsValues = this.state.chipsValues;
        let index = chipsValues.indexOf(chipToDelete.value);
        let deletedValue = chipsValues.splice(index, 1)[0];

        this.setState(
            {
                chips: this.state.chips.filter((chip) => chip.value !== deletedValue),
                chipsValues,
                selectOptions: this.props.options.filter((optn) => !this.state.chipsValues.includes(optn.value))
            },
            () => {
                if (this.props.onChipDelete) {
                    this.props.onChipDelete(chipToDelete);
                }
            }
        );
    };

    render() {
        let selectOptions = this.state.selectOptions;
        if (this.props.sort === 'Asc') {
            selectOptions.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
        } else if (this.props.sort === 'Desc') {
            selectOptions.sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0));
        }
        return (
            <React.Fragment>
                {this.props.isChipOnTop && <DrcChips chips={[...this.state.chips]} onDelete={this.handleChipDelete} sort={this.props.sort} />}
                <DrcSelect className={this.props.classes.berrySelect} onChange={this.handleSelectChange} label={this.props.label || 'Select...'}>
                    {[...selectOptions]}
                </DrcSelect>
                {!this.props.isChipOnTop && <DrcChips chips={[...this.state.chips]} onDelete={this.handleChipDelete} sort={this.props.sort} />}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DrcChipsMultiSelect);
