import { createContext } from 'react';
import { IUserSetup } from '../components/appInitializer/IUserSetup';

interface IAppUserContext {
    userSetup: IUserSetup | null;
    setUserSetup: (userSetup: IUserSetup) => void;
}

const ApplicationUserContextProvider = createContext<IAppUserContext>({} as IAppUserContext);

export default ApplicationUserContextProvider;
