import { useState } from 'react';
import {
    ApplicationPageTitle,
    ApplicationPage,
    DriscollsVContainer,
    DriscollsHContainer,
    DriscollsButton,
    DriscollsTranslatedValue
} from '@driscollsinc/style-guide-web2.0';
import { useNavigate } from 'react-router-dom';
import styles from './Sites.module.scss';
import { NAMESPACE } from '../../../i18n';
import SitesMappingGrid from '../../components/sites/SitesMappingGrid';
import { SiteList } from './mockdata';
import { routes, routesName } from '../../routes/routes';

const Sites = () => {
    const [data, setData] = useState(SiteList.Assignments);
    const navigate = useNavigate();
    return (
        <ApplicationPage className={styles.qmpApplicationPage}>
            <ApplicationPageTitle
                title={{ text: 'Sites', nameSpace: NAMESPACE.SITES }}
                description={{ text: 'Manage the access of QA managers of the internal users', nameSpace: NAMESPACE.SITES }}
            />
            <DriscollsVContainer>
                <DriscollsHContainer className={styles.rightAlign}>
                    <DriscollsButton
                        className={styles.btnLink}
                        label={<DriscollsTranslatedValue value="New" nameSpacing={NAMESPACE.SITES} />}
                        onClick={() => navigate(routes[routesName.CREATE_SITE_MAPPING].getRoute())}
                    />
                </DriscollsHContainer>
                <SitesMappingGrid
                    data={data}
                    selectedPage={1}
                    totalItem={Number(SiteList.Total)}
                    onPageChanged={() => {}}
                    loading={false}
                    itemPerPage={10}
                    onDelete={(id) => {
                        const copy = [...data];
                        copy.splice(Number(id), 1);
                        setData(copy);
                    }}
                />
            </DriscollsVContainer>
        </ApplicationPage>
    );
};

export default Sites;
