import { DuUploadUtilities } from 'driscolls-react-utilities';
import React from 'react';

const fileInput = (returnFunction, ref, statusFunction, acceptableExtensionsArray) => {
    var theArray = acceptableExtensionsArray.join();

    return (
        <input
            ref={ref}
            type="file"
            id="input"
            onChange={(t) => {
                DuUploadUtilities.HandleFiles(t.currentTarget.files, returnFunction, statusFunction, acceptableExtensionsArray);
            }}
            accept={theArray}
        />
    );
};

const DrcUploadUtilities = {
    FileInput: fileInput
};

export default DrcUploadUtilities;
