import { IUserSetup } from '../components/appInitializer/IUserSetup';
import React, { useMemo, useState } from 'react';
import ApplicationUserContext from './ApplicationUserContext';

const ApplicationUserContextProvider = (props: any) => {
    const [userSetup, setUserSetup] = useState<IUserSetup>(null);

    const value = useMemo(
        () => ({
            userSetup,
            setUserSetup,
        }),
        [userSetup]
    );
    return <ApplicationUserContext.Provider value={value}>{props.children}</ApplicationUserContext.Provider>;
};

export default ApplicationUserContextProvider;
