import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';

// Basic Dialog box with dynamic button amount.
// Input properties:
// open: Type bool. Defines whether the dialog is open or closed.
// title: Type String. Defines the title of the dialog box.
// content: Type Sting. Defines the body of the dialog box.
// buttons: Type Buttons. Can handle multiple buttons. Styles must be set on the
//          input buttons
var styles = (theme) => ({
    root: {
        padding: theme.spacing(2, 2),
        minWidth: '300px',
        color: theme.light.text.primary,
        backgroundColor: theme.light.primary,
        [theme.darkTheme]: {
            color: theme.dark.text.primary,
            backgroundColor: theme.dark.primary,
            border: '1px solid ' + theme.dark.buttonBorder,
            borderRadius: '5px'
        }
    },
    title: {
        fontSize: '1.5rem',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(0, 0, 1),
        color: theme.light.accent.primary,
        '& h2': {
            fontSize: '1.5rem'
        },
        [theme.darkTheme]: {
            color: theme.dark.accent.primary
        }
    },
    titleError: {
        color: theme.light.text.errorTitle,
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle
        }
    },
    actions: {
        borderTop: '1px solid ' + theme.dark.buttonBorder + theme.important,
        margin: 0,
        marginTop: theme.spacing(1),
        padding: theme.spacing(1, 0, 0)
    },
    actionWrapper: {
        margin: theme.spacing(-1)
    },
    fullActionWrapper: {
        margin: theme.spacing(-1, 0),
        width: '100%'
    }
});

class DrcDialog extends React.Component {
    render() {
        const { buttons, title, children, open, onClose, isError, classes, className, isConfirmation, fullActionWrapper } = this.props;

        return (
            <Dialog disableBackdropClick={isConfirmation} disableEscapeKeyDown={isConfirmation} open={open} onClose={onClose} className={className}>
                <div className={classes.root}>
                    <DialogTitle className={`${classes.title} ${isError ? classes.titleError : ''}`}>{title}</DialogTitle>
                    <div>{children}</div>
                    <DialogActions className={classes.actions}>
                        <div className={fullActionWrapper ? classes.fullActionWrapper : classes.actionWrapper}>{buttons}</div>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}

export default withStyles(styles)(DrcDialog);
